import React from 'react';
import ArtDetails from '../../components/art/ArtDetails';
import ArtBanner from '../../components/banner/ArtBanner';
import Footer from '../../components/footer/Footer';
import bannerImg from "../../assets/Industries Hero/Real-Estate-Hero.png"
import RealEstateData from "../../assets/data/real-state.js";

function RealEstate(props) {
    const desc = `Real estate encompasses property, land, and buildings. It involves buying, selling, renting, or managing residential, commercial, and industrial properties. The industry is integral to economic development and investment.`;
    return (
        <div className='home-2 wrapper'>
                <ArtBanner image={bannerImg} heading="Real Estate" subheading="Industries" desc={desc}/>

                <ArtDetails data={RealEstateData}/>
                <Footer />
        </div>
    );
}

export default RealEstate;