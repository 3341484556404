import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Events-Hero.jpg"
import EventsData from "../../assets/data/events.js";

function Events(props) {
    const desc ="Events encompass organized gatherings, ranging from conferences and concerts to weddings and sports competitions. They bring people together for shared experiences, networking, entertainment, and celebration, contributing to cultural, social, and professional development.";
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Events" subheading="Industries" desc={desc}/>
            <ArtDetails data={EventsData}/>
            <Footer />
        </div>
    );
}

export default Events;