

const info = [
        {
          title: "Convenience",
          desc: "Enjoy hassle-free shopping from the comfort of your home, avoiding crowded stores and long queues with our Virtual Try-On solution.",
        },
        {
          title: "Confidence Boost",
          desc: `<p>Make informed purchasing decisions by visualizing products on yourself virtually, boosting confidence and reducing the likelihood of returns.</p>`,
          class: "right",
        },
        {
          title: "Personalized Experience",
          desc: "Receive tailored recommendations and discover products that align with your individual preferences and style, enhancing the overall shopping experience.",
        },
        {
          title: "Time and Cost Savings",
          desc: `<p>Save time and money by eliminating the need for multiple store visits and unnecessary returns, thanks to the accuracy and realism of our Virtual Try-On technology.</p>`,
          class: "right",
        },
        {
          title: "Competitive Edge",
          desc: "Stand out in the competitive market by offering an innovative and engaging Virtual Try-On experience that captivates customers and drives sales.",
        },
        
      ];

export default info;