import React from 'react';
import PropTypes from 'prop-types';
import img from '../../assets/images/layouts/couter.png';
import { icon } from '@fortawesome/fontawesome-svg-core';

SolutionCounter.propTypes = {
    data: PropTypes.array,
    heading:PropTypes.string,
    desc:PropTypes.string,
    image:PropTypes.string,
    icon:PropTypes.string,
    showimg:PropTypes.string,
};
SolutionCounter.dafaultPropTypes={
    heading: '',
    desc: '',
    image:img,
    icon: 'true',
    showimg: 'true',
}
function SolutionCounter(props) {
    const {data ,heading, desc , image, icon, showimg} = props;
    return (
        <section className="couter">
            <div className="container">
                <div className="row">
                    {heading &&
                        <div className="col-12 py-5 my-5 mb-0">
                            <div className="block-text center">
                                <h2 className="heading">{heading}</h2>
                                {desc && <p>{desc}</p>}
                            </div>
                        </div>
                    }
                    <div className="col-12">
                        <div className="couter__main">
                            <div className="couter-list">
                                {data.map((idx, index) => (
                                    <div key={index} className="couter-box">
                                        {icon == "true" && <div className="icon">
                                            <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_1065_867)">
                                                        <path d="M8.85351 25.31C6.58552 25.31 4.56812 23.8578 3.83556 21.696L3.78602 21.5331C3.61325 20.9605 3.54088 20.4791 3.54088 19.9974V10.3381L0.104009 21.8106C-0.338018 23.498 0.669255 25.2475 2.35928 25.7136L24.2658 31.5804C24.5393 31.6512 24.8127 31.6852 25.0819 31.6852C26.4928 31.6852 27.7821 30.7487 28.1434 29.3687L29.4197 25.31H8.85351Z" fill="black"/>
                                                        <path d="M8.85351 25.31C6.58552 25.31 4.56812 23.8578 3.83556 21.696L3.78602 21.5331C3.61325 20.9605 3.54088 20.4791 3.54088 19.9974V10.3381L0.104009 21.8106C-0.338018 23.498 0.669255 25.2475 2.35928 25.7136L24.2658 31.5804C24.5393 31.6512 24.8127 31.6852 25.0819 31.6852C26.4928 31.6852 27.7821 30.7487 28.1434 29.3687L29.4197 25.31H8.85351Z" fill="url(#paint0_linear_1065_867)"/>
                                                        <path d="M12.7515 10.4346C14.3141 10.4346 15.5847 9.16373 15.5847 7.60107C15.5847 6.03841 14.3141 4.76758 12.7515 4.76758C11.1888 4.76758 9.91797 6.03841 9.91797 7.60107C9.91797 9.16373 11.1888 10.4346 12.7515 10.4346Z" fill="black"/>
                                                        <path d="M12.7515 10.4346C14.3141 10.4346 15.5847 9.16373 15.5847 7.60107C15.5847 6.03841 14.3141 4.76758 12.7515 4.76758C11.1888 4.76758 9.91797 6.03841 9.91797 7.60107C9.91797 9.16373 11.1888 10.4346 12.7515 10.4346Z" fill="url(#paint1_linear_1065_867)"/>
                                                        <path d="M30.4562 0.517578H9.20573C7.2537 0.517578 5.66406 2.10722 5.66406 4.0595V19.643C5.66406 21.5953 7.2537 23.185 9.20573 23.185H30.4562C32.4085 23.185 33.9982 21.5953 33.9982 19.643V4.0595C33.9982 2.10722 32.4085 0.517578 30.4562 0.517578ZM9.20573 3.35107H30.4562C30.8474 3.35107 31.1647 3.66832 31.1647 4.0595V14.1167L26.6894 8.89457C26.2147 8.33788 25.5275 8.04035 24.7895 8.02348C24.0557 8.02764 23.3672 8.35345 22.8969 8.9174L17.6351 15.2329L15.921 13.5229C14.9521 12.554 13.3752 12.554 12.4076 13.5229L8.49755 17.4316V4.0595C8.49755 3.66832 8.8148 3.35107 9.20573 3.35107Z" fill="black"/>
                                                        <path d="M30.4562 0.517578H9.20573C7.2537 0.517578 5.66406 2.10722 5.66406 4.0595V19.643C5.66406 21.5953 7.2537 23.185 9.20573 23.185H30.4562C32.4085 23.185 33.9982 21.5953 33.9982 19.643V4.0595C33.9982 2.10722 32.4085 0.517578 30.4562 0.517578ZM9.20573 3.35107H30.4562C30.8474 3.35107 31.1647 3.66832 31.1647 4.0595V14.1167L26.6894 8.89457C26.2147 8.33788 25.5275 8.04035 24.7895 8.02348C24.0557 8.02764 23.3672 8.35345 22.8969 8.9174L17.6351 15.2329L15.921 13.5229C14.9521 12.554 13.3752 12.554 12.4076 13.5229L8.49755 17.4316V4.0595C8.49755 3.66832 8.8148 3.35107 9.20573 3.35107Z" fill="url(#paint2_linear_1065_867)"/>
                                                        </g>
                                                        <defs>
                                                        <linearGradient id="paint0_linear_1065_867" x1="48.4466" y1="-20.4157" x2="-16.5545" y2="-11.445" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.164688" stopColor="#DEC7FF"/>
                                                        <stop offset="0.855177" stopColor="#5C27FE"/>
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_1065_867" x1="19.2496" y1="-3.39663" x2="6.61749" y2="-2.13171" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.164688" stopColor="#DEC7FF"/>
                                                        <stop offset="0.855177" stopColor="#5C27FE"/>
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear_1065_867" x1="52.323" y1="-32.1385" x2="-10.4878" y2="-24.2762" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.164688" stopColor="#DEC7FF"/>
                                                        <stop offset="0.855177" stopColor="#5C27FE"/>
                                                        </linearGradient>
                                                        <clipPath id="clip0_1065_867">
                                                        <rect width="34" height="34" fill="white" transform="translate(0 0.452148)"/>
                                                        </clipPath>
                                                        </defs>
                                            </svg>
                                        </div> }
                                        {idx.titleInline && <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h4 className="numb">{idx.count}</h4>
                                            <p style={{fontSize: 20}} className='mx-5'>{idx.titleInline}</p>
                                        </div>}
                                        {idx.title && <h4 className="numb">{idx.count}</h4>}
                                        <p>{idx.title}</p>                                           
                                    </div>
                                ))}
                                {showimg == 'true' && 
                                <div className="couter-box">
                                    <div className="image">
                                        {image ? (
                                            <img src={image} alt="AVRLabs" />
                                        ) : (
                                            <img src={img} alt="AVRLabs" />
                                        )}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SolutionCounter;
