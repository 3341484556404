
import img1 from '../images/layouts/blog-01.png'
import img2 from '../images/layouts/blog-02.png'
import img3 from '../images/layouts/blog-03.png'


const dataBlog = [

    {
        id: 1,
        img: img1,
        title: 'Virtual Designs Hub',
        time: 'February 18, 2022',
        text: 'Revolutionizing Reality: Immersive Training, Virtual Events, Experiential Marketing, 3D Rendering',
        link:"/solutions/S02"
    },
    
]

export default dataBlog;