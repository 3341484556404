
import Metaverse from '../Services/metaverse-services/speciality-icons/Metaverse Integration.svg'
import MetaverseApplications from '../Services/metaverse-services/speciality-icons/Metaverse Applications.svg'
import Metaverse3DSpaceDevelopment from '../Services/metaverse-services/speciality-icons/Metaverse 3D Space Development.svg'
import MetaverseGaming from '../Services/metaverse-services/speciality-icons/Metaverse Gaming.svg'


const dataBox = [

    {
        id: 1,
        title: 'Metaverse Integration',
        desc: 'Seamlessly integrate Metaverse solutions into your existing frameworks, unlocking new levels of connectivity and accessibility across platforms. From API integrations to custom interfaces, we ensure a smooth transition to the Metaverse.',
        img: Metaverse
    },
    {
        id: 2,
        title: 'Metaverse Applications',
        desc: `Immerse yourself in the next generation of immersive experiences with our cutting-edge Metaverse applications. Whether you're exploring virtual worlds or engaging in Immerse yourself in the next generation of immersive experiences with our cutting-edge Metaverse applications. Whether you're exploring virtual worlds or engaging in interactive storytelling, our applications captivate and engage users on a whole new level.`,
        img: MetaverseApplications
    },
    {
        id: 3,
        title: 'Metaverse 3D Space Development',
        desc: 'Dive into immersive virtual environments with our 3D space development services. From architectural visualization to virtual tours, we create stunning 3D spaces that blur the lines between reality and fantasy.',
        img: Metaverse3DSpaceDevelopment
    },
    {
        id: 4,
        title: 'Metaverse Gaming',
        desc: 'Experience the thrill of immersive gaming with our Metaverse gaming solutions. From action-packed adventures to social gaming experiences, our games transport players to new worlds of excitement and wonder.',
        img: MetaverseGaming
    },
]

export default dataBox;