import React from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import AboutService2 from '../components/about/AboutService2';
import Team from '../components/team/AboutTeam';
import dataTeam from '../assets/data/dataTeam';
import Footer from '../components/footer/Footer';

import Button from '../components/button/Button';
import about1Img from '../assets/about/About-us.jpg';
import dataImg from '../assets/about/Shape04.png';
import data1Img from '../assets/about/Shape06.png';
import portfolioImg from '../assets/about/portfolio.jpg';
import SolutionPortfolio from '../components/portfolio/SolutionPortfolio';
import aboutportfolio from '../assets/data/data-about-portfolio';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import dataPartner from '../assets/data/dataPartner'
import SolutionFaqs from '../components/faqs/SolutionFaqs';

function About(props) {

    function handleClick(event) {
        event.preventDefault();
    }
    const portfolioHeaderData = {
        img: portfolioImg,
        heading: 'Because We Can...',
        subheading:'WHY AVR LABS?',
        desc1:'At AVR Labs, we are dedicated to providing our clients with cutting-edge XR solutions and services that help them stand out in the digital world. Here are just a few reasons why you should choose AVR Labs as your XR partner'
    };
    const aboutus = {
        heading: 'Unleashing The Power Of XR Technology',
        subheading: "",
        desc: "AVR Labs, is a revolutionary Augmented, Virtual Reality, and Metaverse company based in Dubai with a commitment to contributing to the UAE’s vision for innovation and evolution. We offer XR solutions and services to help companies solidify their online presence and promote their product and services in an immersive and engaging way.",
        desc3: 'We believe in the power of immersive technologies and their ability to transform and revolutionize the business world.',
        btn: false,
        btnTitle: "More About Us",
        link: '/about',
        img: about1Img
    };
    const data = {
        heading: 'Mission',
        desc: `At AVR Labs, our mission is to empower businesses to leverage the full potential of XR technology. We strive to create innovative and immersive AR & VR solutions that transform the way industries operate, while reducing their impact on the environment. Our goal is to enable businesses to bring their products and services to people's environments using AR and provide unforgettable experiences with VR.`,
        img: dataImg,
    };
    const data1 = {
        heading: 'Vision',
        desc: `To create a sustainable future by revolutionizing the way businesses operate through the power of XR technology. We aim to be the leading provider of XR solutions that make a positive impact on society and the environment.`,
        img: data1Img,
    };
    const teamHeaderData={
        subheading:'OUR TEAM',
        heading:'Our Professionals'
    };
    return (
        <div className='page-about wrapper pt-6'>

            <PageTitle title='About us' />

            <section className="">
                <AboutService2 data={aboutus} />
            </section>
        
            <section className="token my-5 about-top-individual">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12 mb-4">
                            <div className="team-box">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {data.img && (<img src={data.img} className="p-0 mb-2" alt={data.heading}/>)}
                                    {data.heading && (<h5 className="heading mb-2">{data.heading}</h5>)}
                                    {data.desc && (<p className="mb-0" dangerouslySetInnerHTML={{ __html: data.desc }} />)}
                                    {data.btn && (<Button title={data.btnTitle} link={data.link} />)}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="team-box">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {data1.img && (<img className='mb-2' src={data1.img} alt={data1.heading}/>)}
                                    {data1.heading && (<h5 className="heading mb-2">{data1.heading}</h5>)}
                                    {data1.desc && (<p className="mb-0" dangerouslySetInnerHTML={{ __html: data1.desc }} />)}
                                    {data1.btn && (<Button title={data1.btnTitle} link={data1.link} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <SolutionPortfolio headerData={portfolioHeaderData} data={aboutportfolio} step="false"  /> */}
            <SolutionFaqs dataBlock={portfolioHeaderData} data={aboutportfolio}  />
            <Team data={dataTeam} headerData={teamHeaderData}/>
            <section className="partner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">We are partnered with top brands</h3>
                            </div>

                            <Swiper
                                className="brands-swiper"
                                spaceBetween={30}
                                modules={[Pagination,Autoplay]}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}                              
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    991: {
                                        slidesPerView: 6,
                                    },
                                }}
                                loop={true}

                            >
                                {
                                    dataPartner.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <a href="#" onClick={handleClick}><img src={idx.img} alt="AVRLabs"/></a>
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    );
}

export default About;