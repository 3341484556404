
const dataPortfolio = [

    {
        id: 1,
        class: 'left',
        title: 'Certified Team of Experts',
        desc: `Benefit from our certified team of experts with over 15+ years of experience in the field. Our seasoned professionals
                bring a wealth of knowledge and skill to every project, ensuring top-notch quality and reliability.`
    },
    {
        id: 2,
        class: 'right',
        title: 'Extensive Portfolio',
        desc: `With over 50 completed projects around the globe, we have a proven track record of delivering exceptional results.
                Our diverse portfolio showcases our ability to handle projects of varying complexities and scales with ease.`
    },
    {
        id: 3,
        title: 'Interactive Workflow',
        desc: `Our collaborative and iterative process ensures your satisfaction at every step, giving you control over the timeline
                and design decisions.`
    },
    {
        id: 4,
        class: 'right',
        title: 'Trusted by Government Entities',
        desc: `We have had the privilege of working with government entities in the UAE, earning their trust and recognition for our 
                commitment to excellence and professionalism.`
    },
]

export default dataPortfolio;