import img1 from '../../assets/Services/interactive-games/Interactive Activation Games.jpg'

const dataBox = {
        heading: 'Interactive Activation Games',
        button: false,
        link:'',
        btnTitle:'',
        desc: `We offer a dynamic suite of gaming solutions tailored to elevate your marketing strategy and leave a lasting impression on your audience. Our range of interactive games leverages innovative sensor technology, gaming floors, and high-definition screens to create unforgettable experiences for your audience.`,
        dataImg:[
            {
                id: 1,
                img : img1,
                class: 'img1'
            }
        ]
    };

export default dataBox;