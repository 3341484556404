import React , {useState} from 'react';


import Button from '../button/Button';
import img1 from '../../assets/home/main 1.jpg';
import img2 from '../../assets/home/main 2.jpg';

import avt from '../../assets/images/layouts/avt-05.png'






function About3(props) {

    const [dataBlock] = useState(
        {
            subheading: 'WHO WE ARE',
            heading: 'Revolutionizing Industries with the Power of Technology',
            desc1: 'At AVR Labs, we unlock the potential of technology to revolutionize your business. From immersive XR and AI solutions to comprehensive digital tools, we redefine business operations across sectors. Our approach enables captivating audience engagement, elevated marketing strategies, enhanced learning experiences, and a robust online presence.' ,
            desc2: 'Our team of dedicated experts is driven by a passion to empower businesses through technology. We provide scalable solutions tailored to boost your business growth, enhance brand loyalty, and maximize customer engagement'
            
        }
    )
    const [dataCard] = useState(
        [
            {
                id: 1, 
                img: img1,
            },
            {
                id: 2, 
                img: img2,
                class:'sm'
            },
        ]
    )
    return (
        <section className="about s3">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text pd-0">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h4 className="heading">{dataBlock.heading}</h4>
                                <p className="mb-17">{dataBlock.desc1}</p>
                                <p className="mb-26">{dataBlock.desc2}</p>
                                <Button title='More About Us' link='/about' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12 px-5">
                        <div className="about__right" data-aos="fade-left" data-aos-duration="2000">

                            {
                                dataCard.map(idx => (
                                    <div key={idx.id} className={`card-box ${idx.class}`}>
                                        {idx.title && <div className="top d-flex">
                                            <span className="icon-logo-01"></span>
                                            <div>
                                                <h6>{idx.title}</h6>
                                                <h6 className="price">{idx.price}</h6>
                                            </div>
                                        </div>}
                                        <div className="content">
                                            <div className="image">
                                                <img src={idx.img} alt="AVRLabs" />
                                            </div>

                                            {idx.name && <div className="info d-flex">
                                                <div >
                                                    <h6 className="name">{idx.name}</h6>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default About3;