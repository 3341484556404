
import Trade from '../solutions/smart standerds/applications/Trade Shows and Exhibitions.jpg';
import Corporate from '../solutions/smart standerds/applications/Corporate Events2.jpg';
import Product from '../solutions/smart standerds/applications/Product Launches.jpg';
import Marketing from '../solutions/smart standerds/applications/Marketing Campaigns.jpg';
import Brand from '../solutions/smart standerds/applications/Brand Activations.jpg';

const dataProject = [    
    {
        id: 1,
        img: Trade,
        title: 'Trade Shows and Exhibitions'
    },
    {
        id: 2,
        img: Corporate,
        title: 'Corporate Events'
    },
    {
        id: 3,
        img: Product,
        title: 'Product Launches'
    },
    {
        id: 4,
        img: Marketing,
        title: 'Marketing Campaigns'
    },
    {
        id: 5,
        img: Brand,
        title: 'Brand Activations'
    },
]

export default dataProject;