import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Pagination, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

HomeTestimonials.propTypes = {
  data: PropTypes.array
};

function HomeTestimonials(props) {
  const { data } = props;

  const [dataBlock] = useState(
    {
      subheading: 'Testimonials',
      heading: 'What people say',
      // desc: 'Don’t just take our word for it – check out what our XR partners have to say about working with AVR Labs. Our testimonials speak volumes about the quality of our services and the impact we can have on your business',
    }
  )

  return (
    <section className="testimonials">

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="testimonials__main">
              <div className="block-text center">
                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                <h3 className="heading wow" data-splitting>{dataBlock.heading}</h3>
                {/* <p>{dataBlock.desc}</p> */}
                <Swiper

                  spaceBetween={0}
                  slidesPerView={1}
                  className="testimonials-swiper"
                  loop={true}

                  modules={[Pagination,Autoplay]}
                  autoplay={{
                      delay:3000,
                      disableOnInteraction: false,
                  }}
                  pagination
                >
                  {
                    data.map(idx => (
                      <SwiperSlide key={idx.id}>
                        <div className="swiper-slide">
                          <div className="box-testimonial center">
                            <img src={idx.icon} alt="AVRLabs" />
                            <p className="text">{idx.text}</p>
                            <div className="info">
                              {/* <img src={idx.avt} alt="AVRLabs" /> */}
                              <h5 className="name">{idx.name}</h5>
                              <p>{idx.position}</p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                    ))
                  }
                </Swiper>
              </div>
            </div>


          </div>

        </div>
      </div>
    </section >
  );
}

export default HomeTestimonials;