import img1 from "../Services/interactive-virtual-tours/features-icons/Immersive 360.svg";
import img2 from "../Services/virtual-web-development/features-icons/Interactive 3D Elements.svg";
import img3 from "../Services/virtual-web-development/features-icons/VR compatible.svg";
import img4 from "../Services/virtual-web-development/features-icons/Responsive Design.svg";
import img5 from "../Services/virtual-web-development/features-icons/Intuitive Navigation.svg";
import img6 from "../Services/virtual-web-development/features-icons/Advanced Analytics.svg";

const dataBox = [
  {
    id: 1,
    img: img1,
    title: "Virtual Reality Integration",
    desc: "Seamlessly integrate virtual reality elements into your website, providing users with an immersive experience like never before.",
  },
  {
    id: 2,
    img: img2,
    title: "Interactive 3D Elements",
    desc: "Engage users with interactive 3D models, animations, and simulations, bringing your brand to life in vivid detail.",
  },
  {
    id: 3,
    img: img3,
    title: "VR Device Support",
    desc: "Compatible with VR devices, ensuring a seamless experience for users exploring your virtual world.",
  },
  {
    id: 4,
    img: img4,
    title: "Responsive Design",
    desc: "Ensure seamless user experience across all devices with responsive design tailored to desktops, tablets, and smartphones.",
  },
  {
    id: 5,
    img: img5,
    title: "Intuitive Navigation",
    desc: "Provide users with easy-to-use navigation controls for effortless exploration of your virtual space.",
  },
  {
    id: 6,
    img: img6,
    title: "Advanced Analytics",
    desc: "Gain valuable insights into user behavior and preferences with comprehensive analytics tools, empowering data-driven decision-making.",
  },
];

export default dataBox;
