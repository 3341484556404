


const dataFaqs = [
  {
      id: 1,
      title: ' Responsive Touchscreen Brilliance',
      desc : `Equipped with a state-of-the-art touchscreen display, the Interactive Smart Stand provides a seamless and intuitive interaction experience for attendees. With responsive touch capabilities, users can effortlessly navigate through content and engage with your brand.`,
      show: ''
  },
  {
      id: 2,
      title: ' Multimedia Integration',
      desc : `<p>Showcase your products and services with multimedia integration capabilities. Display videos, presentations, product demos, and interactive content to captivate and inform event attendees effectively.<p>`,
      show: ''
  },
  {
      id: 3,
      title: ' Customizable Design',
      desc : `<p>Tailor the Interactive Smart Stand to align with your brand identity and event theme. With customizable graphics and branding options, you can create a stand that reflects your unique brand personality and stands out on the exhibition floor.</p>`,
      show: ''
  },
  {
      id: 4,
      title: ' Remote Management',
      desc : `<p>Stay in control of your Interactive Smart Stand from anywhere with remote management capabilities. Update content, track performance metrics, and make real-time adjustments to optimize the attendee experience effortlessly.</p>`,
      show: '' 
  },
  {
      id: 5,
      title: ' Data Collection',
      desc : `<p>Gain valuable insights into attendee behavior and preferences with built-in data collection features. Collect and analyze data on user interactions to refine your marketing strategies and enhance future events.</p>`,
      show: ''
  },
  {
      id: 6,
      title: ' Dynamic Content Display',
      desc : `<p>Engage event attendees with dynamic content displays that feature interactive elements, eye-catching visuals, and compelling messaging. Capture the attention of passersby and encourage them to explore your booth further.</p>`,
      show: ''
  
  },
]

export default dataFaqs;