import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from '../button/Button';

HomeBanner.propTypes = {
  data: PropTypes.object
};
HomeBanner.defaultProps = {
  data: {
    heading: "",
    subheading: "",
    desc: '',
    video: '',
  }
};
function HomeBanner(props) {
  const { data } = props;
  return (
    <section className="banner top-banner s2 height-85vh" style={{ position: "relative", }}>
      {data.video && (
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: -1 }}>
          <video autoPlay loop muted playsInline style={{ width: "100%", height: "100%", objectFit: "cover" }}>
            <source src={data.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center aligned-center-div">
              {data.heading && <h2 className="heading pd">{data.heading}</h2>}
              {data.subheading && <h6 className="sub-heading"><span>{data.subheading}</span></h6>}
              {data.desc && <h6 className="mb-3">{data.desc}</h6>}
              {data.desc2 && <p className="mb-3">{data.desc2}</p>}
              {data.button && <Button title={data.btnTitle} link={data.link} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBanner;
