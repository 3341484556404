import React from 'react';
import Banner from '../../components/banner/SolutionBanner';
import Footer from '../../components/footer/Footer';
import heroImg from "../../assets/solutions/smart standerds/hero.jpg";
import faqImg from '../../assets/solutions/smart standerds/Innovative-Features.jpg';
import SolutionFaqs from '../../components/faqs/SolutionFaqs';
import smartStanderdsFaqs from '../../assets/data/data-smart-standerd';
import Project from '../../components/project/ProjectService';
import dataProject from '../../assets/data/data-smart-standerd-applications';
import Contact from '../../components/contact/Contact';

function VirtualBeings(props) {
     const bannerData = {
        heading: 'Smart Stands',
        desc:"Introducing the Interactive Smart Stand, the pinnacle of innovation in event technology.",
        images:[heroImg]
    };
    const faqHeader = {
        img: faqImg,
        heading: 'Innovative Features <br /> Unmatched Impact',
    }
    const projectHeader = {
        heading: 'Industry Application',
    }
    return (
        <div className='home-2 wrapper'>
                <Banner data={bannerData} />
                <SolutionFaqs data={smartStanderdsFaqs} dataBlock={faqHeader} />
                <Project data={dataProject} headerData={projectHeader} />
                <Contact   subheading='Get In touch' heading="Ready to Elevate Your Events?"  infoheading="Let’s Talk!"/>
                <Footer />
        </div>
    );
}

export default VirtualBeings;