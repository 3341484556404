
import resa from '../solutions/AI virtual being/icons/Rasa (including RasaX, Botfront).svg';
import img2 from '../solutions/AI virtual being/icons/botpress.svg';
import google from '../solutions/AI virtual being/icons/Google Dialogflow04.svg';
import mbf from '../solutions/AI virtual being/icons/Microsoft Bot Framework.svg';
import img5 from '../solutions/AI virtual being/icons/OpenAI Green.svg';
import img6 from '../solutions/AI virtual being/icons/Microsoft_Azure.svg'

const dataBox = [

    {
        id: 1,
        img: resa,
        title: 'Rasa (Including RasaX, Botfront)',
        desc: 'Rasa is an open source machine learning framework for automated text and voice-based conversations. Connect your Rasa chatbot to Ai Virtual Assistant.'
    },
    {
        id: 2,
        img: img2,
        title: 'Botpress',
        desc: 'Botpress is a modern developer stack to build enterprise and open-source chatbots, which can be turned into Ai Virtual Assistant.'
    },
    {
        id: 3,
        img: google,
        title: 'Google Dialogflow',
        desc: 'Dialogflow is a natural language understanding platform that makes it easy to design and integrate a conversational user interface. Import your Dialogflow project into Virtual being.'
    },
    {
        id: 4,
        img: mbf,
        title: 'Microsoft Bot Framework',
        desc: 'Bot Framework Composer lets you build chatbots with a visual authoring canvas, and extend with code. Connect your Microsoft Bot to Virtual being.'
    },
    {
        id: 5,
        img: img5,
        title: 'OpenAI GPT-3 (Add documents)',
        desc: 'You can ultimately transform any ConversationalAI into an AI chatbot. Check out the docs to see how to make a GPT-3 based Ai Chatbot.'
    },
    {
        id: 6,
        img: img6,
        title: 'Microsoft Azure',
        desc: `Seamlessly integrated with the robust infrastructure of Microsoft Azure. Enhance your virtual experiences with unmatched reliability, scalability, and security. 
        Experience the limitless possibilities of AI innovation with the trusted performance of one of the world’s top cloud platforms.`,
    },
]

export default dataBox;