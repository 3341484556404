
import retailsDisplay from '../solutions/Transparant screens/Icons/Retail Displays.svg'
import museum from '../solutions/Transparant screens/Icons/Museums and Exhibits.svg'
import corporate from '../solutions/Transparant screens/Icons/Corporate Presentations.svg'
import hospitality from '../solutions/Transparant screens/Icons/Hospitality Environments.svg'
import trade from '../solutions/Transparant screens/Icons/Trade Show Booths.svg'
import informational from '../solutions/Transparant screens/Icons/Informational Kiosks.svg'


const dataBox = [

    {
        id: 1,
        img: retailsDisplay,
        title: 'Retail Displays',
        desc: 'Enhance retail environments with captivating displays that showcase products in a dynamic and engaging way.'
    },
    {
        id: 2,
        img: museum,
        title: 'Museums and Exhibits',
        desc: 'Bring history and art to life with interactive displays that educate and inspire visitors.'
    },
    {
        id: 3,
        img: corporate,
        title: 'Corporate Presentations',
        desc: 'Impress clients and stakeholders with visually stunning presentations in boardrooms and conferences.'
    },
    {
        id: 4,
        img: hospitality,
        title: 'Hospitality Environments',
        desc: 'Transform hotel lobbies and event spaces with immersive displays that highlight amenities and promote events.'
    },
    {
        id: 5,
        img: trade,
        title: 'Trade Show Booths',
        desc: 'Stand out at trade shows with attention-grabbing displays that showcase products and services uniquely.'
    },
    {
        id: 6,
        img: informational,
        title: 'Informational Kiosks',
        desc: 'Provide valuable information and assistance to visitors in airports, malls, and public spaces with modern and engaging displays.'
    },

]

export default dataBox;