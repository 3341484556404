import React , {useState} from 'react';
import Button from '../button/Button';
import PropTypes from 'prop-types';

import img from '../../assets/images/layouts/create.png'

Create.propTypes = {
    data: PropTypes.object,
};
Create.dafaultPropTypes={
    data:{
        heading: '',
        desc: '',
        btn:true,
        btnTitle:'Join  Now',
        link:'/contact',
    }
}

function Create(props) {

    const {data} = props;
    return (
        <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    {data.heading && <h4 className="heading" dangerouslySetInnerHTML={{ __html: data.heading }} />}
                                    {data.desc && <p>{data.desc}</p>}
                                    {data.btn && <Button title={data.btnTitle} link={data.link} />}
                                </div>
                                {data.img ? (
                                    <img src={data.img} alt="AVRLabs" />
                                ) : (
                                    <img src={img} alt="AVRLabs" />
                                )}      
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Create;