import React from 'react';
import dataVirtualBeingsPortfolio from '../../assets/data/data-virtual-beings-portfolio';
import virtualBeingsFaqs from '../../assets/data/data-virtual-beings-faq';
import Banner from '../../components/banner/SolutionBanner';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import SolutionFaqs from '../../components/faqs/SolutionFaqs';
import Footer from '../../components/footer/Footer';
import portfolioImg from '../../assets/solutions/AI virtual being/AI-Virtual-Beings.png';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import dataBox from '../../assets/data/data-virtual-beings-speciality';
import data from '../../assets/data/data-virtual-beings-deploy'
import infodata from '../../assets/data/data-virtual-beings-info'
import ProjectInfo from "../../components/project/ProjectInfo";
import Contact from '../../components/contact/Contact';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import headerVideo from "../../assets/solutions/AI virtual being/Header.mp4";

function VirtualBeings(props) {
    const counterHeaing ="Pushing the Boundaries of Technology";
    const counterDesc = "We're committed to delivering reliable, customer-centric solutions that push the boundaries of technology. With AR Viewz, businesses can stay ahead of the curve and delight customers with cutting-edge AR experiences that set them apart from the competition.";
    const bannerData = {
        heading: 'AI Virtual Beings',
        desc:"Seamless Multi-Channel Customer Experience Let Our Personalized AI Virtual Beings Do The Talking!",
        button:true,
        link:'/contact',
        btnTitle:"Get your own AI Virtual Being",
        video:headerVideo
    };
    const headerData = {
        img:portfolioImg,
        subheading: 'Avatars Supported In Our SDKs',
        heading: 'Enterprise-Ready AI-Powered Virtual Beings',
    };
    const SpecialityData = {
        heading: 'Transform Conversational AI Into AI Virtual Assistant!',
    };
    const faqHeader = {
        subheading: 'Innovative Features',
        heading: 'Unmatched Impact',
    };
    const info = {
        subheading: 'There For You 24/7',
        heading: 'Available To Your Clients Even When You Or Your Employees Are Not Around',
        desc:'In multi-channel reality your customers want answers delivered on their terms and at a time and place that they see fit.'
    };
    return (
        <div className='home-2 wrapper'>
                <Banner data={bannerData} />
                <SolutionPortfolio headerData={headerData} data={dataVirtualBeingsPortfolio} step="false"/>
                <SolutionSpeciality data={dataBox} headerData={SpecialityData}/>
                <section className="about">
                    <div className="shape right"></div>
                        <div className="container">
                        <div className="row text-center">
                            <div className="col-xl-12 col-md-12">
                                <div className="project-box mb-0">
                                    <div style={{ position: 'relative' }}>
                                    <virbe-plugin
                                        mode="standalone"
                                        locationId="45cd4f9c-912b-40d5-93a5-04d3826e2722"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            
                                        }}/>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </section>
                <section className="partner">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-12">
                                <div className="block-text center">
                                    <h4 className="heading">Create Once - Deploy Anywhere</h4>
                                    <p>Launch virtual beings on Web, Mobile, Kiosks, VR, and AR with SDKs for Javascript, Unity and Unreal Engine.</p>
                                </div>

                                <Swiper
                                    className="brands-swiper"
                                    spaceBetween={30}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        991: {
                                            slidesPerView: 3,
                                        },
                                    }}
                                    loop={true}

                                >
                                    {
                                        data.map(idx => (
                                            <SwiperSlide key={idx.id}>
                                                <img src={idx.img} alt="AVRLabs"/>
                                            </SwiperSlide>
                                        ))
                                    }

                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
                <ProjectInfo data={infodata} heading={info.heading} subheading={info.subheading} desc={info.desc}/>
                <Contact   subheading='Get in touch' heading='Discover A World Where AI Technology Meets Human-Like Engagement' desc="Contact us to get your personalized AI Virtual Being." />
                <Footer />
        </div>
    );
}

export default VirtualBeings;