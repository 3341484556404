import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Marketing-Hero.jpg"
import MarketingData from "../../assets/data/marketing.js";

function Marketing(props) {
    const desc ="Marketing involves activities and strategies aimed at promoting and selling products or services. It includes market research, advertising, branding, and customer engagement, with the goal of attracting, satisfying, and retaining customers while achieving organizational objectives.";
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Marketing" subheading="Industries" desc={desc}/>
            <ArtDetails data={MarketingData}/>
            <Footer />
        </div>
    );
}

export default Marketing;