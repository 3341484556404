

const info = [
        {
          title: "Seamless Web Integration",
          desc: "With just a few lines of code, add Ai Chatbot to your website. Make sure each customer is being served in a more personalized way.",
        },
        {
          title: "Virtual Beings In XR And Metaverse",
          desc: `<p>Elevate your immersive experience with Ai Chatbot on VR and AR devices. Our technology allows seamless integration of virtual beings into your app or game.</p><p>Discover A World Where AI Technology Meets Human-Like Engagement Contact us to get your personalized Ai Virtual Being (contact form)</p>`,
          class: "right",
        },
        {
          title: "Versatile Deployment",
          desc: `<p>Seamlessly integrates with various platforms and touchpoints including websites, apps, and VR devices.</p>`,
        },
        {
          title: "24/7 Availability",
          desc: `<p>Ensures continuous access to your clients, even when you or your employees are unavailable.</p>`,
          class: "right",
        },        
      ];

export default info;