
import Portability from '../solutions/Holograpic displays/icons/Portability.svg';
import Gesture from '../solutions/Holograpic displays/icons/Gesture Interaction.svg';
import Dynamic from '../solutions/Holograpic displays/icons/Dynamic Presentations.svg';

const dataBox = [

    {
        id: 1,
        img: Portability,
        title: 'Portability',
        desc: 'Take your brand on the go with lightweight and easy-to-transport holographic displays, perfect for presentations, events, and retail installations.'
    },
    {
        id: 2,
        img: Gesture,
        title: 'Gesture Interaction',
        desc: 'Engage with your audience in an intuitive and immersive manner using gesture-based interaction, elevating the level of interactivity and engagement.'
    },
    {
        id: 3,
        img: Dynamic,
        title: 'Dynamic Presentations',
        desc: 'Command attention and leave a lasting impression with dynamic holographic presentations that captivate audiences and deliver your message with impact.'
    },
]

export default dataBox;