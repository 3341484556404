import React from 'react';
import dataVirtualTryOnPortfolio from '../../assets/data/data-virtual-try-on-portfolio';
import Banner from '../../components/banner/SolutionBanner';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import Footer from '../../components/footer/Footer';
import portfolioImg from '../../assets/solutions/Virtual try on/portfolio.jpg';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import dataTryOn from '../../assets/data/data-virtual-try-on-speciality';
import infodata from '../../assets/data/data-virtual-try-on-info'
import ProjectInfo from "../../components/project/ProjectInfo";
import Create from '../../components/create/Create2';
import headerVideo from "../../assets/solutions/Virtual try on/Hero.mp4";
import ctaImg from "../../assets/solutions/Virtual try on/cta.png";
function VirtualBeings(props) {
     const bannerData = {
        heading: 'Virtual Try-On',
        subheading:"Introducing Virtual Try-On Solutions!",
        desc:"Our cutting-edge Virtual Try-On technology is designed to revolutionize the way consumers shop online. With our innovative solution, customers can experience a new dimension of virtual shopping, seamlessly trying on products from the comfort of their homes.",
        video: headerVideo
    };
    const headerData = {
        img:portfolioImg,
        heading: 'Explore Cutting-Edge Features',
    };
    const SpecialityData = {
        heading: 'Core Applications',
    };
    const info = {
        heading: 'Exceptional Benefits',
    };
    const contactData = {
        heading: 'Experience the future of online shopping with <br> Virtual Try-On solutions by AVR Labs.',
        desc: 'Ready to Transform Your Online Shopping Experience?',
        btn: true,
        btnTitle: 'Book your FREE Consultation Today!',
        link: '/contact',
        img:ctaImg
    }
    return (
        <div className='home-2 wrapper'>
                <Banner data={bannerData} />
                <SolutionPortfolio headerData={headerData} data={dataVirtualTryOnPortfolio} step="false"/>
                <SolutionSpeciality data={dataTryOn} headerData={SpecialityData}/>
                <ProjectInfo data={infodata} heading={info.heading} />
                <Create data={contactData} />
                <Footer />
        </div>
    );
}

export default VirtualBeings;