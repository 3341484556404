import React from 'react';
import dataTransparentPortfolio from '../../assets/data/data-transparent-screens-portfolio';
import Banner from '../../components/banner/SolutionBanner';
import Roadmap from '../../components/roadmap/Roadmap2';
import Footer from '../../components/footer/Footer';
import portfolioImg from '../../assets/solutions/Transparant screens/transparant-screens1.png';
import hoverImg from '../../assets/solutions/Transparant screens/transparant-screens2.png';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import dataApplications from '../../assets/data/data-transparent-screens-applications';
import headerImg from "../../assets/solutions/Transparant screens/Header.gif";
import ctaImg from "../../assets/solutions/Transparant screens/CTA.png";
import Create from '../../components/create/Create2';

function VirtualBeings(props) {
     const bannerData = {
        heading: 'Transparent Screens',
        desc:"Elevate your visual communication with AVR Labs Transparent Screens – an innovative solution that seamlessly merges digital content with real-world environments. These cutting-edge transparent screens redefine how information is presented, offering a futuristic and engaging experience for viewers.",
        images:[headerImg],
    };
    const headerData = {
        img:portfolioImg,
        hoverImg: hoverImg,
        subheading: '',
        heading: 'Experience Enhanced Engagement',
    };
    const SpecialityData = {
        subheading: 'Our Speciality',
        heading: 'Application',
    };
    const contactData = {
        heading: 'Unlock the Power of Visual Communication!',
        desc:'Elevate engagement, inspire action, and create memorable experiences that leave a lasting impact on your audience.',
        btn: true,
        btnTitle: 'Talk to our experts to transform your visual communication strategy',
        link: '/contact',
        img:ctaImg
    }
    return (
        <div className='home-2 wrapper'>
                <Banner data={bannerData} />
                <Roadmap headerData={headerData} data={dataTransparentPortfolio}/>
                <SolutionSpeciality data={dataApplications} headerData={SpecialityData}/>
                <Create style={{ marginTop: "100px" }} data={contactData} />
                <Footer />
        </div>
    );
}

export default VirtualBeings;