import React, { useState, useEffect, cloneElement } from 'react';
import LoadingComponent from './Loading';

const MediaLoader = ({ children }) => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        let totalMedia = 0;
        let loadedMediaCount = 0;

        const handleMediaLoad = () => {
            loadedMediaCount++;
            if (loadedMediaCount === totalMedia) {
                setLoading(false);
            }
        };

        const imageElements = Array.from(document.images);
        const videoElements = document.querySelectorAll('video');
        const iframeElements = document.querySelectorAll('iframe');

        const checkMediaElements = () => {
            totalMedia += imageElements.length + videoElements.length + iframeElements.length;
            imageElements.forEach(img => {
                if (img.complete) {
                    handleMediaLoad();
                } else {
                    img.addEventListener('load', handleMediaLoad);
                    img.addEventListener('error', handleMediaLoad);
                }
            });

            videoElements.forEach(video => {
                if (video.readyState >= 4) {
                    handleMediaLoad();
                } else {
                    video.addEventListener('canplaythrough', handleMediaLoad, { once: true });
                    video.addEventListener('loadedmetadata', handleMediaLoad, { once: true });
                    video.addEventListener('error', handleMediaLoad, { once: true });
                }
            });

            iframeElements.forEach(iframe => {
                iframe.addEventListener('load', handleMediaLoad);
                iframe.addEventListener('error', handleMediaLoad);
            });

            if (totalMedia === 0) {
                setLoading(false);
            }
        };

        checkMediaElements();
        return () => {
            imageElements.forEach(img => {
                img.removeEventListener('load', handleMediaLoad);
                img.removeEventListener('error', handleMediaLoad);
            });
            videoElements.forEach(video => {
                video.removeEventListener('canplaythrough', handleMediaLoad);
                video.removeEventListener('loadedmetadata', handleMediaLoad);
                video.removeEventListener('error', handleMediaLoad);
            });
            iframeElements.forEach(iframe => {
                iframe.removeEventListener('load', handleMediaLoad);
                iframe.removeEventListener('error', handleMediaLoad);
            });
        };
    }, [children]); // Consider adjusting this dependency if `children` changes too frequently

    return (
        <>
            {isLoading && <LoadingComponent />}
            {cloneElement(children, { style: isLoading ? { display: 'none' } : {} })}
        </>
    );
};

export default MediaLoader;
