import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Architecture-Hero.jpg";
import ArchitectureData from "../../assets/data/architecture.js";

function Architecture(props) {
    const desc ="Architecture encompasses the design, planning, and construction of structures, reflecting aesthetic, functional, and cultural considerations. It spans a wide range of projects, including residential homes, commercial buildings, and public spaces, shaping the physical environment in which we live and work.";
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Architecture" subheading="Industries" desc={desc}/>
            <ArtDetails data={ArchitectureData}/>
            <Footer />
        </div>
    );
}

export default Architecture;