
import img1 from '../Services/interactive-virtual-tours/virtual tour school.jpg'
import img2 from '../Services/interactive-virtual-tours/virtual tour constructions.jpg'
import img3 from '../Services/interactive-virtual-tours/virtual tour collections 5.jpg'
import img4 from '../Services/interactive-virtual-tours/virtual tour commercial.jpg'
import img5 from '../Services/interactive-virtual-tours/virtual-tour-clinics.jpg'

const dataProject = [    
    {
        id: 1,
        img: img1,
        title: 'School Virtual Tours'
    },
    {
        id: 2,
        img: img2,
        title: 'Industrial Virtual Tours'
    },
    {
        id: 3,
        img: img3,
        title: 'Residential Virtual Tours'
    },
    {
        id: 4,
        img: img4,
        title: 'Commercial Virtual Tours'
    },
    {
        id: 5,
        img: img5,
        title: 'Clinics & Hospitals Virtual Tours'
    },
    
]

export default dataProject;