import React from 'react';
import dataVDHPortfolio from '../../assets/data/data-vdh-portfolio';
import dataVDHFeatures from '../../assets/data/data-VDH-features';
import Banner from '../../components/banner/SolutionBanner';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import SolutionCounter from '../../components/counter/SolutionCounter';
import Footer from '../../components/footer/Footer';
import portfolioImg from '../../assets/solutions/virtual designs hub/portfolio.png';
import Button from '../../components/button/Button';
import Contact from '../../components/contact/Contact';
import headerVideo from '../../assets/solutions/virtual designs hub/Hero.mp4';
import counterImg from '../../assets/solutions/virtual designs hub/couter.png';
function VDH(props) {
    const counterHeaing = "Boosting Your Business's Expansion with the Power of Virtual Reality";
    const counterDesc = "";
    const video = "https://virtualdesignshub.com/viewer/index.php?code=642e92efb79421734881b53e1e1b18b6";

    const bannerData = {
        heading: 'Virtual Designs Hub',
        desc: "Revolutionizing Reality: Immersive Training, Virtual Events, Experiential Marketing, 3D Rendering",
        button: true,
        link: '/contact',
        btnTitle: "Talk to our Experts",
        video: headerVideo
    };
    const headerData = {
        img: portfolioImg,
        subheading: 'Welcome to Virtual Designs Hub',
        heading: 'We bring your visions to life with immersive experiences',
    };
    const viewzCounter = [
        {
            count: '40%',
            title: 'Increase in Client Engagement'
        },
        {
            count: '90%',
            title: 'Reduction in Client Misinterpretation',
        },
        {
            count: '85%',
            title: 'Savings on Physical Models'
        },
        {
            count: '20+',
            title: 'Languages Available'
        }
    ];
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <SolutionPortfolio headerData={headerData} data={dataVDHPortfolio} step="false" />
            {video && (<section className="project s2">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xl-10 offset-xl-1 col-md-12">
                            <div className="project-box">
                                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                                    <iframe
                                        src={video}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write;"
                                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                        title="AVRLabs"
                                        className="embed-responsive-item"
                                    ></iframe>
                                </div>
                            </div>
                            <a className="action-btn" href="https://virtualdesignshub.com/vr/villa" target="_blank"><span>Click to preview</span></a>
                        </div>
                    </div>
                </div>
            </section>)}
            <SolutionCounter data={viewzCounter} heading={counterHeaing} desc={counterDesc} icon="false" showimg="true" image={counterImg} />
            <section className="touch">
                <div className="container">
                    <div className="row text-center">
                        <h4 className="my-4">Features</h4>
                        {dataVDHFeatures.map(feature => (
                            <div className="col-xl-4 col-12 col-md-6 col-sm-12 my-2" key={feature.id}>
                                <div className="team-box">
                                    <div className="image p-5 ">
                                        <img className="mx-auto center" src={feature.img} alt="AVRLabs" />
                                        <h6 className="center">{feature.title}</h6>
                                        {feature.desc && <p className="">
                                            {feature.desc}
                                        </p>}
                                    </div>
                                </div>
                            </div>))
                        }
                    </div>
                </div>
            </section>
            <Contact heading='Book your FREE Consultation' />
            <Footer />
        </div>
    );
}

export default VDH;