import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

function SolutionBanner({ data }) {
    const [backgroundIndex, setBackgroundIndex] = useState(0);

    useEffect(() => {
        let interval;
        if (data.images && data.images.length > 1) {
            interval = setInterval(() => {
                setBackgroundIndex(prevIndex => (prevIndex + 1) % data.images.length);
            }, 3000);
        }
        return () => clearInterval(interval);
    }, [data.images]);

    const bannerStyle = {
        position: "relative",
        minHeight:"400px",
    };

    if (data.images) {
        bannerStyle.backgroundImage = `url(${data.images[backgroundIndex]})`;
        bannerStyle.backgroundSize='cover';
        bannerStyle.backgroundPosition= 'center';
    }
    return (
        <section className="banner top-banner s2 height-85vh" style={bannerStyle}>
            {data.video && (
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: -1 }}>
                    <video autoPlay loop muted playsInline style={{ width: "100%", height: "100%", objectFit: data.objFitContain ? "contain" : "cover" }}>
                        <source src={data.video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center aligned-center-div">
                            {data.heading && <h2 className="heading pd">{data.heading}</h2>}
                            {data.subheading && <h6 className="sub-heading"><span>{data.subheading}</span></h6>}
                            {data.desc && <p className="mb-3">{data.desc}</p>}
                            {data.desc2 && <p className="mb-3">{data.desc2}</p>}
                            {data.button && <Button title={data.btnTitle} link={data.link} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

SolutionBanner.propTypes = {
    data: PropTypes.object.isRequired
};

export default SolutionBanner;
