import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../loading/Loading';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import img from '../../assets/contact/touch.png';

Contact.propTypes = {
    heading: PropTypes.string,
    desc: PropTypes.string,
    subheading: PropTypes.string,
    infoheading: PropTypes.string,
};
Contact.defaultProps = {
    heading: '',
    subheading: '',
    heading2: '',
    infoheading:''
};
function Contact(props) {
    const navigate = useNavigate();
    const { heading, subheading, desc,infoheading } = props;
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        industry: '',
        phone: '',
        message: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const validateForm = () => {
        let errors = {};
        if (!formData.firstName.trim()) errors.firstName = 'First name is required';
        if (!formData.lastName.trim()) errors.lastName = 'Last name is required';
        if (!formData.email.trim()) errors.email = 'Email is required';
        if (!formData.industry.trim()) errors.industry = 'Industry is required';
        if (!formData.phone.trim()) errors.phone = 'Phone number is required';
        if (!formData.message.trim()) errors.message = 'Message is required';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setErrorShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async e => {
        const apiUrl = process.env.REACT_APP_API_URL;
        e.preventDefault();
        try {
            setLoading(true);
            if (!validateForm()) {
                setLoading(false);
                return; 
            }
            setShowAlert(false);
            setErrorShowAlert(false);
            const response = await fetch(`${apiUrl}/contact-form-handler.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    industry: '',
                    phone: '',
                    message: ''
                });
                setShowAlert(true);
                setLoading(false);
                navigate('/thank-you');
            } else {
                setLoading(false);
                setErrorShowAlert(true);
            }
        } catch (error) {
            setLoading(false);
            setErrorShowAlert(true);
        }
    };
    const contactData = {
        phone: '+971 52 495 5029',
        addres: 'Sheikh Zayed Road, Al Qouz First, Dubai UAE',
        email: 'info@avrlabs.io',
    };
    const industries = [
        'Art', 'Retail Stores', 'Real Estate', 'Education', 'E-Commerce', 'Events', 'Architecture', 'Health Care', 'Hospitality', 'Marketing', 'Manufacturers', 'Other'
    ];
    return (

        <section className="touch">
             <Helmet>
                <script type='text/javascript'>
                {`
                    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                    r.src=n;var u=t.getElementsByTagName(s)[0];
                    u.parentNode.insertBefore(r,u);})(window,document,
                    'https://sc-static.net/scevent.min.js');
                    
                    snaptr('track', 'PAGE_VIEW');
                    snaptr('track', 'CUSTOM_EVENT_1', {'uuid_c1': 'INSERT_UUID_C1', 'sign_up_method': 'INSERT_SIGN_UP_METHOD', 'user_email': 'INSERT_USER_EMAIL', 'user_phone_number': 'INSERT_USER_PHONE_NUMBER'})
                `}
                </script>
            </Helmet>
            {loading && <LoadingSpinner />}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            {subheading && <h6 className="sub-heading"><span>{subheading}</span></h6>}
                            {heading && <h4 className="heading" dangerouslySetInnerHTML={{ __html: heading }} />}
                            {desc && <p className="">{desc}</p>}
                        </div>
                        <div className="touch__main">
                            <div className="info">
                                {infoheading ? (
                                    <h5>{infoheading}</h5>
                                ) : (
                                 <h5>Contact information</h5>
                                )}
                                <ul className="list">
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7V7Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7V7Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p>{contactData.addres}</p>
                                    </li>
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_794_6441)">
                                                <path d="M1.5 4.5C1.5 10.0227 5.97733 14.5 11.5 14.5H13C13.3978 14.5 13.7794 14.342 14.0607 14.0607C14.342 13.7794 14.5 13.3978 14.5 13V12.0853C14.5 11.7413 14.266 11.4413 13.932 11.358L10.9833 10.6207C10.69 10.5473 10.382 10.6573 10.2013 10.8987L9.55467 11.7607C9.36667 12.0113 9.042 12.122 8.748 12.014C7.65659 11.6128 6.66544 10.9791 5.84319 10.1568C5.02094 9.33456 4.38725 8.34341 3.986 7.252C3.878 6.958 3.98867 6.63333 4.23933 6.44533L5.10133 5.79867C5.34333 5.618 5.45267 5.30933 5.37933 5.01667L4.642 2.068C4.60143 1.9058 4.50781 1.7618 4.37604 1.65889C4.24426 1.55598 4.08187 1.50006 3.91467 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V4.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_794_6441">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p><a href={`tel:${contactData.phone}`}>{contactData.phone}</a></p>
                                    </li>
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5 4.5V11.5C14.5 11.8978 14.342 12.2794 14.0607 12.5607C13.7794 12.842 13.3978 13 13 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V4.5M14.5 4.5C14.5 4.10218 14.342 3.72064 14.0607 3.43934C13.7794 3.15804 13.3978 3 13 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5M14.5 4.5V4.662C14.5 4.9181 14.4345 5.16994 14.3096 5.39353C14.1848 5.61712 14.0047 5.80502 13.7867 5.93933L8.78667 9.016C8.55014 9.16169 8.2778 9.23883 8 9.23883C7.7222 9.23883 7.44986 9.16169 7.21333 9.016L2.21333 5.94C1.99528 5.80569 1.81525 5.61779 1.69038 5.3942C1.56551 5.1706 1.49997 4.91876 1.5 4.66267V4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p><a href={`mailto:${contactData.email}`}>{contactData.email}</a></p>
                                    </li>
                                </ul>

                                <div className="image">
                                    <img src={img} alt="AVRLabs" />
                                </div>
                            </div>

                            <form onSubmit={handleSubmit} className="form-box">
                                {showAlert && (
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        Email sent successfully!
                                        <button type="button" className="close" onClick={() => setShowAlert(false)} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}
                                {showErrorAlert && (
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        Failed to send email, Please try again later.
                                        <button type="button" className="close" onClick={() => setShowAlert(false)} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-xl-6 col-md-12">
                                        <label >First name</label>
                                        <input type="text" value={formData.firstName} name="firstName" onChange={handleChange} className={`form-control ${formErrors.firstName ? 'is-invalid' : ''}`} />
                                        {formErrors.firstName && <div className="invalid-feedback">{formErrors.firstName}</div>}
                                    </div>
                                    <div className="col-xl-6 col-md-12">
                                        <label >Last name</label>
                                        <input type="text" value={formData.lastName} name="lastName" onChange={handleChange} className={`form-control ${formErrors.lastName ? 'is-invalid' : ''}`} />
                                        {formErrors.lastName && <div className="invalid-feedback">{formErrors.lastName}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-md-12">
                                        <label >Email</label>
                                        <input type="email" value={formData.email} name="email" onChange={handleChange} className={`form-control ${formErrors.email ? 'is-invalid' : ''}`} />
                                        {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                                    </div>
                                    <div className="col-xl-6 col-md-12">
                                        <label >Phone Number</label>
                                        <input type="number" value={formData.phone} name="phone" onChange={handleChange} className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`} />
                                        {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <label>Industry</label>
                                        <select
                                            name="industry"
                                            value={formData.industry}
                                            onChange={handleChange}
                                            className={`form-control ${formErrors.industry ? 'is-invalid' : ''}`}
                                        >
                                            <option value="">Select Industry</option>
                                            {industries.map((industry, index) => (
                                                <option key={index} value={industry}>{industry}</option>
                                            ))}
                                        </select>
                                        {formErrors.industry && <div className="invalid-feedback">{formErrors.industry}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label >Message</label>
                                        <textarea name="message" id="mess" cols="30" rows="10" value={formData.message} onChange={handleChange} className={`form-control ${formErrors.message ? 'is-invalid' : ''}`} ></textarea>
                                        {formErrors.message && <div className="invalid-feedback">{formErrors.message}</div>}
                                    </div>
                                </div>
                                <div className="row mb-0">
                                    <div className="col-lg-12">
                                        <button className="action-btn" type="submit" disabled={loading}><span>Send Now</span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;