// App.js
import React, { useState, useEffect } from "react"; // Make sure useEffect is included
import { Routes, Route, useLocation } from "react-router-dom";
import AOS from "aos";

import routes from "./pages";
import Page404 from "./pages/404";
import Header from "./components/header/Header";
import MediaLoader from "./components/loading/MediaLoader"; // Import the MediaLoader

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [location]);

  return (
    <>
      <Header />
      <Routes>
        {routes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <MediaLoader key={location.pathname}>
                {route.component}
              </MediaLoader>
            }
          />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}

export default App;
