import React , {useState} from 'react';
import PropTypes from 'prop-types';

import img1 from '../../assets/images/layouts/about-01.png'
import img2 from '../../assets/images/layouts/about-02.png'
import img3 from '../../assets/images/layouts/about-03.png'
import img4 from '../../assets/images/layouts/about-04.png'
import img5 from '../../assets/images/layouts/about-05.png'
import Button from '../button/Button';

AboutSolution.propTypes = {
    data: PropTypes.object.isRequired,
   
};

// AboutSolution.defaultProps = {
//     data:{
//         heading: 'test',
//         subheading: '',
//         showButton: false,
//         desc:'',
//         dataImg:[
//             {
//                 id: 1,
//                 img : img1,
//                 class: 'img1'
//             },
//             {
//                 id: 2,
//                 img : img2,
//                 class: 'img2'
//             },
//             {
//                 id: 3,
//                 img : img3,
//                 class: 'img3'
//             },
//             {
//                 id: 4,
//                 img : img4,
//                 class: 'img4'
//             },
//             {
//                 id: 5,
//                 img : img5,
//                 class: 'img5'
//             },
//         ]
//     }
// };


function AboutSolution(props) {

    const {data} = props;
    return (
        <section className="about about-first">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        data.dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="AVRLabs" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                {data.subheading && <h6 className="sub-heading"><span>{data.subheading}</span></h6>}
                                {data.heading && <h3 className="heading">{data.heading}</h3> }
                                {data.desc && <p className="mb-17">{data.desc}</p> }
                                {data.desc2 && <p className="mb-26">{data.desc2}</p>}
                                {data.button && <Button link={data.btnLink} title={data.btnTitle} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default AboutSolution;