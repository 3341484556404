import virtualYearbookImg from "../industries/Education/virtual yearbook.jpg";
import immersiveRealityImg from "../industries/Education/immersive reality.jpg";
import workshopsImg from "../industries/Education/workshop & training.jpg";


const vimeoVideoUrls = {
    interactiveVirtualTour: "https://player.vimeo.com/video/936134178?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",    
    virtualLabs: "https://player.vimeo.com/video/936162933?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",    
};
const dataItem = [
  {
    id: 11,
    img: immersiveRealityImg,
    iframe: '',
    title: "Immersive Reality Curriculum Development",
    desc: `<p>Craft educational curriculam enriched with immersive reality, blending augmented and virtual experiences to enhance student engagement and foster a dynamic learning environment.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
  },
  {
    id: 1,
    img: '',
    iframe: 'https://portal.arviewz.com/products/175/modelviewer?arModel=true',
    title: "AR for Education",
    desc: `<p>Integrate Augmented Reality into education for interactive and engaging learning experiences, bringing subjects to life through digital overlays, simulations, and interactive content.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
  },
  {
    id: 2,
    img: '',
    iframe: '',
    title: "Interactive Virtual Tour",
    desc: `<p>Enhance education with interactive virtual tours, providing students immersive journeys through historical landmarks, scientific environments, or cultural sites, enriching their understanding and exploration.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['interactiveVirtualTour'],
    link: "/solutions/A08",
  },
  {
    id: 3,
    img: workshopsImg,
    title: "Workshop & Training",
    desc: `<p>Conduct workshops and training sessions, equipping educators and students with skills in emerging technologies, fostering a hands-on approach to learning for a future-ready educational experience.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
  {
    id: 4,
    img: virtualYearbookImg,
    title: "Virtual Yearbook",
    desc: `<p>Revolutionize traditions with a virtual yearbook, offering an interactive and multimedia-rich platform for students to celebrate memories, achievements, and personal growth in a digital and dynamic format.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
  {
    id: 5,
    img: '',
    title: "Virtual Labs",
    desc: `<p>Transform traditional labs into virtual spaces, allowing students to conduct experiments, explore scientific concepts, and collaborate in a simulated environment, enhancing practical learning in a digital realm.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['virtualLabs'],
    link: "",
  },
];

export default dataItem;
