
import img1 from '../solutions/virtualbeings/javascript-js-seeklogo.png'
import img2 from '../solutions/virtualbeings/unity.png'
import img3 from '../solutions/virtualbeings/Unreal-Engine.png'
const dataPartner = [

    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },   

]

export default dataPartner;