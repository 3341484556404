
const dataPortfolio = [

    {
        id: 'One',
        title: 'Innovative Solutions',
        desc: `We thrive on pushing the boundaries of what's possible, constantly innovating to deliver experiences that defy expectations and leave a lasting mark on your audience.`
    },
    {
        id: 'Two',
        title: 'Tailored to You',
        desc: `Your brand is unique, and your events should be too. That's why we take the time to understand your goals and objectives, crafting customized solutions that resonate with your audience and drive real results.`
    },
    {
        id: "Three",
        title: 'Certified Experts',
        desc: `With over <b>15 years</b> of experience, our team of certified experts brings a wealth of knowledge and skill to every project. You can trust us to deliver top-notch quality and reliability every time.`
    },
]

export default dataPortfolio;