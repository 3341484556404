
import beauty from '../solutions/Virtual try on/Icons/Beauty.png'
import accessories from '../solutions/Virtual try on/Icons/Accessories.png'
import eyewear from '../solutions/Virtual try on/Icons/Eyeware.png'


const dataBox = [

    {
        id: 1,
        img: beauty,
        title: 'Beauty ',
        desc: 'Experiment with makeup products, from lipstick to foundation, to discover your ideal shades and enhance your beauty routine.'
    },
    {
        id: 2,
        img: accessories,
        title: 'Accessories',
        desc: 'Explore a diverse range of accessories, including sunglasses, hats, and jewelry, to elevate your look with confidence and style.'
    },
    {
        id: 3,
        img: eyewear,
        title: 'Eyewear',
        desc: 'Find the perfect pair of glasses or sunglasses by virtually trying on different frames, ensuring optimal comfort and style.'
    },
]

export default dataBox;