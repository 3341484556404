import React from 'react';
import ArtDetails from '../../components/art/ArtDetails';
import ArtBanner from '../../components/banner/ArtBanner';
import Footer from '../../components/footer/Footer';
import bannerImg from "../../assets/Industries Hero/Art-Hero-photo.jpg";
import dataArt from "../../assets/data/data-art";

function Art(props) {
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Art" subheading="Industries" />
            <ArtDetails data={dataArt}/>
            <Footer />
        </div>
    );
}

export default Art;