
import img1 from '../../assets/Services/interactive-virtual-tours/Virtual Tour (main).jpg'
import img2 from '../../assets/Services/interactive-virtual-tours/virtual tour mini (1).jpg'
import img3 from '../../assets/Services/interactive-virtual-tours/virtual tour mini (2).jpg'
import img4 from '../../assets/Services/interactive-virtual-tours/virtual tour mini (3).jpg'
import img5 from '../../assets/Services/interactive-virtual-tours/virtual tour mini (4).jpg'

const dataBox = {
        heading: 'Interactive 360° Virtual Tour',
        button: true,
        link:'/about',
        btnTitle:'More About Us',
        desc: `Step into the future of storytelling with Interactive Virtual Tours, where every click unfolds a captivating narrative, showcasing your brand, location, and vision like never before.`,
        desc2: `Interactive Virtual Tours tailored specifically for schools, where every click unlock a captivating journey into learning environments. Transport students, parents, and educators into your educational spaces, offering a glimpse into the facilities, resources, and ambiance that define your institution with immersive 360º virtual tours.`,
        dataImg:[
            {
                id: 1,
                img : img1,
                class: 'img1'
            },
            {
                id: 2,
                img : img2,
                class: 'img2'
            },
            {
                id: 3,
                img : img3,
                class: 'img3'
            },
            {
                id: 4,
                img : img4,
                class: 'img4'
            },
            {
                id: 5,
                img : img5,
                class: 'img5'
            },
        ]
    };

export default dataBox;