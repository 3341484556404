import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Healthcare-Hero.png"
import HealthcareData from "../../assets/data/healthcare.js";

function Healthcare(props) {
    const desc ="Healthcare involves the maintenance and improvement of physical and mental well-being through medical services, treatments, and preventive measures. It encompasses a broad spectrum, including hospitals, clinics, research, pharmaceuticals, and public health initiatives.";
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Health Care" subheading="Industries" desc={desc}/>
            <ArtDetails data={HealthcareData}/>
            <Footer />
        </div>
    );
}

export default Healthcare;