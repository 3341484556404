
import img1 from '../Services/interactive-virtual-tours/features-icons/Photorealistic 3D rendering.svg'
import img2 from '../Services/interactive-virtual-tours/features-icons/Immersive 360.svg'
import img3 from '../Services/interactive-virtual-tours/features-icons/Interactive elements (texts, videos, pictures, audio, links).svg'
import img4 from '../Services/virtual-web-development/features-icons/VR compatible.svg'
import img5 from '../Services/interactive-virtual-tours/features-icons/Integration with app, website, CRM, or go-to-market systems.svg'
import img6 from '../Services/interactive-virtual-tours/features-icons/Compatible with all camera.svg'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Photorealistic 3D rendering',
    },
    {
        id: 2,
        img: img2,
        title: 'Immersive 360º experiences',
    },
    {
        id: 3,
        img: img3,
        title: 'Interactive elements (texts, videos, pictures, audio, links)',
    },
    {
        id: 4,
        img: img4,
        title: 'VR Device Support',
    },
    {
        id: 5,
        img: img5,
        title: 'Integration with app, website, CRM, or go-to-market systems',
    },
    {
        id: 6,
        img: img6,
        title: 'Compatible with all camera',
    },
]

export default dataBox;