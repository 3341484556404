


import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import Team from "./Team";
import VisionsMission from "./VisionsMission";
import Project from "./Project";
import ProjectDetails from "./ProjectDetails";
import Art from "./industries/Art";
import RetailStores from "./industries/RetailStores";
import RealEstate from "./industries/RealEstate";
import Education from "./industries/Education";
import Manufacturers from "./industries/Manufacturers";
import Ecommerce from "./industries/Ecommerce";
import Events from "./industries/Events";
import Architecture from "./industries/Architecture";
import Healthcare from "./industries/Healthcare";
import Hospitality from "./industries/Hospitality";
import Marketing from "./industries/Marketing";
import Arviewz from "./solutions/Arviewz";
import VirtualDesignHub from "./solutions/VirtualDesignHub";
import VirtualBeings from "./solutions/virtualBeings";
import TransparentOled from "./solutions/TransparentOled";
import VirtualTryOn from "./solutions/VirtualTryOn";
import SmartStanderd from "./solutions/SmartStanderd";
import HolographicDisplays from "./solutions/HolographicDisplays";
import ExtendedReality from "./services/ExtendedReality";
import Metaverse from "./services/Metaverse";
import InteractiveVirtualTours from "./services/InteractiveVirtualTours";
import VirtualWebDevelopment from "./services/VirtualWebDevelopment";
import CreativeConceptsActivation from "./services/CreativeConceptsActivation";
import InteractiveGames from "./services/InteractiveGames";
import Policy from "./PrivacyPolicy";
import SuccessPage from "./SuccessPage";

const routes = [
  { path: '/', component: <Home01 />},
  { path: '/home-v2', component: <Home02 />},
  { path: '/home-v3', component: <Home03 />},

  { path: '/nfts', component: <Nfts />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/visions-mission', component: <VisionsMission />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/participants-assets', component: <ParticipantAssets />},
  { path: '/advisors-backers', component: <AdvisorsBackers />},
  { path: '/partners', component: <Partners />},
  { path: '/about', component: <About />},
  { path: '/road-map', component: <RoadMap />},
  { path: '/team', component: <Team />},
  { path: '/contact', component: <Contact />},
  { path: '/projects', component: <Project />},
  { path: '/project/:slug', component: <ProjectDetails />},
  { path: '/industries/art', component: <Art />},
  { path: '/industries/retail-stores', component: <RetailStores />},
  { path: '/industries/real-estate', component: <RealEstate />},
  { path: '/industries/education', component: <Education />},
  { path: '/industries/manufacturers', component: <Manufacturers />},
  { path: '/industries/ecommerce', component: <Ecommerce />},
  { path: '/industries/events', component: <Events />},
  { path: '/industries/architecture', component: <Architecture />},
  { path: '/industries/healthcare', component: <Healthcare />},
  { path: '/industries/hospitality', component: <Hospitality />},
  { path: '/industries/marketing', component: <Marketing />},
  { path: '/solutions/S01', component: <Arviewz />},
  { path: '/solutions/S02', component: <VirtualDesignHub />},
  { path: '/solutions/S03', component: <VirtualBeings />},
  { path: '/solutions/S05', component: <TransparentOled />},
  { path: '/solutions/S08', component: <VirtualTryOn />},
  { path: '/solutions/S07', component: <HolographicDisplays />},
  { path: '/solutions/S04', component: <SmartStanderd />},
  { path: '/services/A02', component: <ExtendedReality />},
  { path: '/services/A03', component: <Metaverse />},
  { path: '/services/A08', component: <InteractiveVirtualTours />},
  { path: '/services/A05', component: <VirtualWebDevelopment />},
  { path: '/services/A06', component: <CreativeConceptsActivation />},
  { path: '/services/A04', component: <InteractiveGames />},
  { path: '/privacy-policy', component: <Policy />},
  { path: '/thank-you', component: <SuccessPage />},

]

export default routes;