import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import gameAboutData from '../../assets/data/data-interactive-game-about';
import portfolioImg from '../../assets/Services/interactive-games/Revolutionizing-Gaming.png';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import dataGamePortfolio from '../../assets/data/data-game-portfolio';
import dataChooseUsPortfolio from '../../assets/data/data-game-choose-us-portfolio';
import specialityDataBox from '../../assets/data/data-game-speciality';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import chooseUsPortfolioImg from '../../assets/Services/interactive-games/Why-Choose-Us.png';
import contactImg from '../../assets/Services/interactive-games/CTA.png';

function InteractiveGames(props) {
    const SpecialityData = {
        heading: 'Why Interactive Kinetic Games?',
        cls: "col-xl-4 col-md-6"
    };
    const chooseUsHeader = {
        heading: "Why Choose Us?",
        desc: `<p>With over <b>15 years</b> of experience and certified experts in immersive technology, AVR Labs is a trusted leader in the field.</p>
                <p>Our team brings a wealth of knowledge and expertise to every project, ensuring that your event is a success from concept to execution. </p>
                <p>Your trusted partner with industry professionals who are committed to deliver exceptional results.</p>`,
        img: chooseUsPortfolioImg
    };
    const bannerData = {
        heading: 'Interactive Games',
        subheading: "",
        desc: "Engage Your Audience, Drive Results",
        button: false,
        btnTitle: "",
        link: '',
    };
    const portfolioHeaderData = {
        heading:'Revolutionizing Gaming',
        subheading: 'Sensory Symphony',
        img: portfolioImg
    };
    const contactData = {
        heading: 'Partner with Us',
        desc: "Let's create an unforgettable experience for your audience together.",
        btn: true,
        btnTitle: 'Contact Us',
        link: '/contact',
        img: contactImg
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <AboutService data={gameAboutData} />
            <SolutionPortfolio headerData={portfolioHeaderData} data={dataGamePortfolio} step='false'/>
            <SolutionSpeciality data={specialityDataBox} headerData={SpecialityData} />
            <div className="mb-5">
                <SolutionPortfolio headerData={chooseUsHeader} data={dataChooseUsPortfolio} step='false' numbering='true' />
            </div>
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default InteractiveGames;