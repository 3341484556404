
const dataPortfolio = [

    {
        id: 1,
        title: 'Engage and Excite',
        desc: 'Our photorealistic 3D rendering and interactive features captivate potential investors and buyers, simplifying the outreach and fundraising process for architects, designers, and real estate developers.'
    },
    {
        id: 2,
        title: 'Drive Sales',
        desc: 'Harness the power of visual storytelling to ignite enthusiasm, engagement, and interest among prospects, leading to increased conversions and sales.'
    },
    {
        id: 3,
        title: 'Provide Clarity',
        desc: 'Present key project details and your vision with crystal clarity, ensuring viewers easily understand and connect with your message.'
    },
    {
        id: 4,
        title: 'Autopilot or Take Control',
        desc: 'Offer a fully guided virtual tour or let viewers explore at their own pace, catering to different preferences and enhancing user experience.ognized worldwide for its excellence.'
    },
    {
        id: 5,
        title: 'Multilingual Support',
        desc: 'Reach a global audience by providing accurate subtitling or narration in multiple languages, ensuring clear communication and understanding.'
    },
    {
        id: 6,
        title: 'ISO 9001 Certificate',
        desc: 'With interactive virtual tours, the process of obtaining ISO 9001 certificate becomes easier and faster.'
    },
    {
        id: 7,
        title: 'Easy to Share',
        desc: 'With a simple link, embed the tour on your website or share it via email or social media, making it effortless for viewers to access and enjoy.'
    },

]

export default dataPortfolio;