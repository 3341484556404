import workshop from "../home/slider/Workshops.jpg";
import AR from "../home/slider/AR.jpg";
import VR from "../home/slider/VR.jpg";
import Metaverse from "../home/slider/Metaverse-Services.jpg";
import Creative from "../home/slider/Creative-Concepts.jpg";
import Transparent from "../home/slider/Transparent-Screen.jpg";
import Holographic from "../home/slider/Holographic-Displays.jpg";
import Virtual from "../home/slider/Virtual-Try-on.jpg";



const dataCard = [
  {
    id: 1,
    img: workshop,
    title: "Workshops",
  },
  {
    id: 2,
    img: AR,
    title: "Augmented Reality - AR",
    //link: '/services/A02'
  },
  {
    id: 3,
    img: VR,
    title: "Virtual Reality - VR",
    //link: '/services/A02'
  },
  {
    id: 4,
    img: Metaverse,
    title: "Metaverse Services",
    //link:'/services/A03',
  },
  {
    id: 5,
    img: Creative,
    title: "Creative Concepts",
    //link:'/services/A06',
  },
  {
    id: 6,
    img: Transparent,
    title: "Transparent Screens",
    //link:'/solutions/S05',
  },
  {
    id: 7,
    img: Holographic,
    title: "Holographic Displays",
    //link:'/solutions/S07',
  },
  {
    id: 8,
    img: Virtual,
    title: "Virtual Try-on",
    //link:'/solutions/S08',
  },
];

export default dataCard;
