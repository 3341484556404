
import img1 from '../../assets/Services/virtual-web-development/Main.jpg'
import img2 from '../../assets/Services/virtual-web-development/02.jpg'
import img3 from '../../assets/Services/virtual-web-development/03.jpg'
import img4 from '../../assets/Services/virtual-web-development/04.jpg'
import img5 from '../../assets/Services/virtual-web-development/05.jpg'

const dataBox = {
        heading: 'Transformative Experiences That Captivate Audiences',
        subheading: 'Elevating Online Presence',
        button: true,
        link:'/contact',
        btnTitle:'Talk to our Experts!',
        desc: `From showcasing products in stunning detail to providing interactive company profiles, we transform your online presence into an engaging experience that resonates with your audience.`,
        desc2: ``,
        dataImg:[
            {
                id: 1,
                img : img1,
                class: 'img1'
            },
            {
                id: 2,
                img : img2,
                class: 'img2'
            },
            {
                id: 3,
                img : img3,
                class: 'img3'
            },
            {
                id: 4,
                img : img4,
                class: 'img4'
            },
            {
                id: 5,
                img : img5,
                class: 'img5'
            },
        ]
    };

export default dataBox;