import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Education-Hero.jpg"
import EducationData from "../../assets/data/education.js";

function Education(props) {
    const desc = `Education is the key to knowledge and personal growth. It empowers individuals, fostering critical thinking, creativity, and skill development. A lifelong journey, education shapes minds, careers, and societies.`;
    return (
        <div className='home-2 wrapper'>
                <ArtBanner image={bannerImg} heading="Education" subheading="Industries" desc={desc}/>
                <ArtDetails data={EducationData}/>
                <Footer />
        </div>
    );
}

export default Education;