import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import webAboutData from '../../assets/data/data-virtual-web-about';
import dataBox from '../../assets/data/data-VWD-speciality';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import portfolioImg from '../../assets/Services/virtual-web-development/Discover-the-power-of-Virtual-Websites.jpg';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import dataVWDportfolio from '../../assets/data/data-VWD-portfolio';
import about1Img from '../../assets/images/layouts/about-06.png'
import token1Img from '../../assets/Services/virtual-web-development/Experience-the-Difference.jpg'
import token2Img from '../../assets/Services/virtual-web-development/Transform-Your-Company-Profile.jpg'
import contactImg from '../../assets/Services/virtual-web-development/Say-goodbye-to-static.png';
import SolutionCounter from '../../components/counter/SolutionCounter';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
function VirtualWebDevelopment(props) {
    const Counterstat = [{
        count: '40%',
        titleInline: "Increase in user engagement reported by the brands using virtual websites",
    }];
    const SpecialityData = {
        heading: 'Highlighted Features',
        cls: "col-xl-4 col-md-6"
    };
    const bannerData = {
        heading: 'Virtual Web Development',
        subheading: "",
        desc: "Transform Your Web Presence with Interactive Virtual Websites.",
        button: false,
        btnTitle: "",
        link: '',
    };
    const token2Data = {
        heading: 'Experience the Difference',
        desc: `See how we can transform any space into an immersive virtual showroom, complete with live product updates and intuitive navigation.
        `,
        btn: true,
        btnTitle: "View Demo",
        link: 'https://virtualdesignshub.com/stagingnew/viewer/index.php?code=c0c7c76d30bd3dcaefc96f40275bdc0a',
        iframe: '',
        video: '',
        img: token1Img
    }
    const token2Data2 = {
        heading: 'Transform Your Company Profile',
        desc: `Did you know? We can convert your static company profile PDF into an interactive virtual website, providing an engaging platform to showcase your brand and offerings.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token2Img,
    }

    const contactData = {
        heading: 'Contact Us',
        desc: 'Say goodbye to static presentations and hello to interactive digital experiences that leave a lasting impact.',
        btn: true,
        btnTitle: 'Contact us now to start your digital transformation journey',
        link: '/contact',
        img: contactImg
    }
    const portfolioHeaderData = {
        img: portfolioImg,
        heading: 'Discover the power of Virtual Websites',
    };
    const aboutservice2Data1 = {
        heading: '',
        subheading: "",
        desc2: 'We specialize in creating immersive virtual websites that redefine how businesses connect with their audience online. ',
        desc: "We take your traditional website design to new heights by leveraging WebVR and WebGL technologies to create interactive 3D environments. ",
        btn: false,
        btnTitle: "",
        link: '',
        img: about1Img
    };
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <section className="mx-5 px-5">
                <AboutService data={webAboutData} />
            </section>
            <section className="my-5 py-5">
                {/* <AboutService2 data={aboutservice2Data1} /> */}
                <SolutionPortfolio headerData={portfolioHeaderData} data={dataVWDportfolio} step="false" numbering="true" />
            </section>
            <SolutionCounter data={Counterstat} className="my-5" />
            <ServiceToken2 data={token2Data} />
            <ServiceToken data={token2Data2} />
            <SolutionSpeciality data={dataBox} headerData={SpecialityData} />
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default VirtualWebDevelopment;