
// import img1 from '../../assets/images/layouts/about-01.png'
// import img2 from '../../assets/images/layouts/about-02.png'
// import img3 from '../../assets/images/layouts/about-03.png'
// import img4 from '../../assets/images/layouts/about-04.png'
// import img5 from '../../assets/images/layouts/about-05.png'
import img1 from '../../assets/Services/extended-reality/Larger photo.jpg'
import img2 from '../../assets/Services/extended-reality/xr 2.jpg'
import img3 from '../../assets/Services/extended-reality/xr 3.jpg'
import img4 from '../../assets/Services/extended-reality/xr 4.jpg'
import img5 from '../../assets/Services/extended-reality/xr 5.jpg'

const dataBox = {
        heading: 'Your Gateway to Limitless Possibilities',
        button: true,
        link:'/about',
        btnTitle:'More About Us',
        desc: `Experience technology like never before with our XR  services. From captivating customer interactions to empowering employee training, our XR solutions are designed to elevate every aspect of your journey. `,
        dataImg:[
            {
                id: 1,
                img : img1,
                class: 'img1'
            },
            {
                id: 2,
                img : img2,
                class: 'img2'
            },
            {
                id: 3,
                img : img3,
                class: 'img3'
            },
            {
                id: 4,
                img : img4,
                class: 'img4'
            },
            {
                id: 5,
                img : img5,
                class: 'img5'
            },
        ]
    };

export default dataBox;