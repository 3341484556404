import React from 'react';
import './loading.css';
import Lottie from 'react-lottie';
import animationData from '../../assets/loading/logo animation.json';
const LoadingSpinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div></div>
    // <div className="loading-spinner-overlay">
    //   <div className='loading-div'>
    //     <Lottie options={defaultOptions}/>
    //   </div>
    // </div>
  );
};

export default LoadingSpinner;
