
import img1 from '../Services/metaverse-services/metaverse education.jpg'
import img2 from '../Services/metaverse-services/metaverse architecture.jpg'
import img3 from '../Services/metaverse-services/metaverse healthcare.jpg'
import img4 from '../Services/metaverse-services/metaverse constructions.jpg'
import img5 from '../Services/metaverse-services/metaverse real estate.jpg'
import img6 from '../Services/metaverse-services/metaverse manufacturers.jpg'

const dataProject = [    
    {
        id: 1,
        img: img1,
        title: 'Metaverse in Education'
    },
    {
        id: 2,
        img: img2,
        title: 'Metaverse in Architecture'
    },
    {
        id: 3,
        img: img3,
        title: 'Metaverse in Healthcare'
    },
    {
        id: 4,
        img: img4,
        title: 'Metaverse in Construction'
    },
    {
        id: 5,
        img: img5,
        title: 'Metaverse in Real Estate'
    },
    {
        id: 6,
        img: img6,
        title: 'Metaverse in Manufacturing'
    },  
]

export default dataProject;