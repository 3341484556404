import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import creativeAboutData from '../../assets/data/data-CCA-about';
import portfolioImg from '../../assets/Services/creative-concepts-and-activation/why-choose-avr-labs.jpg'
import contactImg from '../../assets/Services/creative-concepts-and-activation/CTA2.png';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import dataCCAportfolio from '../../assets/data/data-CCA-portfolio';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';

function CreativeConceptsActivation(props) {
    const holoVideo="https://player.vimeo.com/video/937289481?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const roboticVideo="https://player.vimeo.com/video/937290286?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const agrealityVideo="https://player.vimeo.com/video/937292378?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const transparentVideo="https://player.vimeo.com/video/937292397?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const curvedVideo="https://player.vimeo.com/video/937293737?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const vbVideo='https://player.vimeo.com/video/936100460?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0';
    const bannerData = {
        heading: 'Creative Concepts, Activation & Events',
        subheading: "",
        desc: "Where Brands Come to Life",
        button: false,
        btnTitle: "",
        link: '',
    };
    const token2Data = {
        heading: 'Robotic Precision',
        desc: `From product launches to trade shows to corporate events, our robotic arms are incredibly versatile and can be customized to suit a wide range of applications. Whether you're looking to entertain, educate, or inspire your audience, our robotic arms are the perfect tool for the job.`,
        btn: false,
        btnTitle: "View Demo",
        link: '',
        iframe: '',
        video: roboticVideo,
        img: ''
    }
    const token2Data2 = {
        heading: 'Holobox Immersion',
        desc: `Holobox Immersion is more than just a display – it's a powerful tool for brands to create immersive and memorable experiences at events. With its ability to captivate audiences and deliver messages with impact, the Holobox is the future of communication in the events industry.`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: holoVideo,
        img: ''
    }
    const token2Data3 = {
        heading: 'Augmented Reality Engagement',
        desc: `<ul><li>- Transform your events into interactive experiences with augmented reality. Engage your audience on a whole new level, driving deeper connections and lasting memories.</li>
        <li>- Interactive Product Demos</li>
        <li>- Immersive Experiences</li>
        <li>- Virtual Avatars</li>
        <li>- Brand  Awareness</li>
        <li>- QR Code Integration</li></ul>`,
        btn: false,
        btnTitle: "View Demo",
        link: '',
        iframe: '',
        video: agrealityVideo,
        img: ''
    }
    const token2Data4 = {
        heading: 'Curved Screen Spectacle',
        desc: `Transform your presentations with our state-of-the-art curved screen technology. Engage your audience in a visually captivating experience that enhances your message and leaves a memorable impact.`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: curvedVideo,
        img: ''
    }
    const token2Data5 = {
        heading: 'Transparent Screens',
        desc: `Transparency meets innovation with our screens. Showcase your content in crystal-clear quality while maintaining transparency, creating a seamless blend of digital and physical space.`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: transparentVideo,
        img: ''
    }
    const token2Data6 = {
        heading: 'High-Tech Event Booth',
        desc: `<p>We carefully design event booths tailored to your brand with the latest technology to give your brand an unforgettable experience. Here's why they stand out:<p>
        <ul>
            <li>-  Audio-Visual and Technology Integration</li>
            <li>- Video Walls & Displays</li>
            <li>- Interactive Kiosks with AI Assistant</li>
            <li>- Soundscapes and Ambiance</li>
            <li>- Product Demonstrations with Augmented Reality (AR) Interactions</li>
            <li>- Virtual Reality (VR) Experiences</li>
            <li>- Experiential Zones</li>
            <li>- Transparent Screens of Any Shapes</li>
            <li>- Interactive Sensor Game Floor</li>
        </ul>
        <p>With our high-tech event stands, you can elevate your brand presence, engage your audience, and create unforgettable experiences that drive success at your events.<p>`,
        btn: false,
        btnTitle: "View Demo",
        link: '',
        iframe: 'https://portal.arviewz.com/products/346/modelviewer?arModel=true',
        video: '',
        img: ''
    }
    const token2Data7 = {
        heading: 'AI Virtual Being',
        desc: `Harness the power of artificial intelligence to create lifelike virtual beings that engage and interact with your audience. Whether it's customer service avatars or brand ambassadors, our AI virtual beings drive engagement and enhance the attendee experience.`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: vbVideo,
        img: ''
    }

    const contactData = {
        heading: 'Contact Us',
        desc: 'Transform your next event into an unforgettable brand experience.',
        btn: true,
        btnTitle: 'Contact Us Today for Free Consultation',
        link: '/contact',
        img: contactImg
    }
    const portfolioHeaderData = {
        img: portfolioImg,
        heading: 'Why Choose AVR Labs?',
    };
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <AboutService data={creativeAboutData} />
            <section>
                <div className="block-text center">
                    <h2 className="text-center my-3"><span>Crafting Experiences That Convert</span></h2>
                    <p className="text-center">From dynamic robotic displays to mesmerizing holographic presentations, we specialize in pushing the boundaries of creativity to craft experiences that captivate, inspire, and convert.</p>
                </div>
            </section>
            <ServiceToken2 data={token2Data} />
            <ServiceToken data={token2Data2} />
            <ServiceToken2 data={token2Data3} />
            <ServiceToken data={token2Data4} />
            <ServiceToken2 data={token2Data5} />
            <ServiceToken data={token2Data6} />
            <ServiceToken2 data={token2Data7} />
            <SolutionPortfolio headerData={portfolioHeaderData} data={dataCCAportfolio} step='false' numbering='true' />
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default CreativeConceptsActivation;