
import img1 from '../solutions/AI virtual being/icons/ready-player-me.png';
import img2 from '../solutions/AI virtual being/icons/Unreal-MetaHuman.png';
import img3 from '../solutions/AI virtual being/icons/Character-Creator.png';
import img4 from '../solutions/AI virtual being/icons/Daz3D.png';



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Ready Player Me',
        desc: 'Create your avatar using your selfie and customizable elements available on the cross-game avatar platform for Metaverse.'
    },
    {
        id: 2,
        img: img2,
        title: 'Unreal MetaHuman',
        desc: 'Use Unreal Engine Metahuman Creator to create a high-fidelity digital human, taking your Ai Chatbot into a new level of believability.'
    },
    {
        id: 3,
        img: img3,
        title: 'Character Creator',
        desc: 'Arrive with deeply-detailed, stylized or realistic looking avatars from Reallusion that will enhance the visual quality of your brand virtual persona.'
    },
    {
        id: 4,
        img: img4,
        title: 'Daz3D',
        desc: 'Stylized, fantastic and realistic looking avatars anything you can think of to represent your brand. Powered by Daz Studio.'
    },


]

export default dataPortfolio;