
import img1 from '../partners/DMF.png'
import img2 from '../partners/Orange.png'
import img3 from '../partners/ESE.png'
import img4 from '../partners/MOE.png'
import img5 from '../partners/aramco-1024x310.png'
import img6 from '../partners/bni.png'
import img7 from '../partners/UJM.png'
import img8 from '../partners/Rattan-House-white.png'
import img9 from '../partners/Bateel.png'
import img10 from '../partners/Asset-1.png'
import img11 from '../partners/Asset-9.png'

const dataPartner = [

    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
    {
        id: 5,
        img: img5,
    },
    {
        id: 6,
        img: img6,
    },
    {
        id: 7,
        img: img7,
    },
    {
        id: 8,
        img: img8,
    },
    {
        id: 9,
        img: img9,
    },
    {
        id: 10,
        img: img10,
    },
    {
        id: 11,
        img: img11,
    },
    

    

]

export default dataPartner;