
import img1 from '../../assets/solutions/Holograpic displays/Pyramid Stand.jpg'
const dataBox = {
        heading: 'Pyramid Stand',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `The Pyramid Stand offers a unique mixed-reality display experience by combining physical objects with holographic video elements. `,
        desc2:'Experience an immersive viewing angle for diverse applications.',
        img : img1,
    };

export default dataBox;