


const dataFaqs = [
    {
        id: 3,
        title: ' Customizable AR Experiences',
        text : `<p>Tailored to Fit Your Brand and Business Needs.</p><p>Our customizable AR experiences are designed to seamlessly integrate with your existing platforms and branding. With options to adjust size, pattern, lighting, and animations, AR Viewz offers unparalleled flexibility to create immersive experiences that perfectly align with your business goals. Showcase your products in a way that's uniquely yours.</p>`,
        show: ''
    
    },
    {
        id: 4,
        title: ' Easy Integration',
        text : `<p>Seamlessly integrates with popular e-commerce platforms such as WooCommerce, Magento, Shopify, and others. This makes it easy for businesses to add AR functionality to their existing websites without the need for extensive technical expertise.</p>`,
        show: ''
    
    },
    {
        id: 5,
        title: ' Wide Device Compatibility',
        text : `<p>Compatible with iOS and Android devices, ensuring that customers can access AR experiences regardless of their device preferences.</p>`,
        show: ''
    
    },
]

export default dataFaqs;