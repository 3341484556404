import interactiveVirtualTourimg from "../industries/Real Estate/real estate tour.jpg";
import interactiveInstallationsImg from "../industries/Real Estate/interactive-installations.png";

const vimeoVideoUrls = {
    virtualDesigns: "https://player.vimeo.com/video/929219923?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    interactiveInstallations: "https://player.vimeo.com/video/936100081?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    AI: "https://player.vimeo.com/video/936100460?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    modelAr: "https://player.vimeo.com/video/936106630?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    
};
const dataItem = [
  {
    id: 1,
    img: interactiveVirtualTourimg,
    title: "Interactive Virtual Tour",
    desc: `<p>Experience real estate like never before with interactive virtual tours. Navigate through properties seamlessly, explore details, and envision spaces, all from the comfort of your device. Effortlessly immersive, anytime, anywhere and Works for Designed  and ready projects.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn More",
    video: "",
    link: "/services/A08",
  },
  {
    id: 2,
    img: '',
    iframe: 'https://virtualdesignshub.com/viewer/zohour',
    title: "Virtual Designs",
    desc: `<p>Revolutionize real estate with virtual design. Visualize spaces through digital models, explore layout possibilities, and customize interiors virtually. Elevate property presentation, offering a dynamic and personalized vision for potential buyers.<p>`,
    button: true,
    slug: "S02",
    btnTitle: "Learn More",
    video: '',
    link: "/solutions/S02",
  },
  {
    id: 3,
    img: interactiveInstallationsImg,
    title: "Interactive Installations",
    desc: `<p>Elevate real estate engagement with interactive installations. From virtual property walkthroughs to dynamic presentations and holograms, these immersive experiences captivate potential buyers, offering a compelling glimpse into future living spaces.<p>`,
    button: true,
    slug: "A06",
    btnTitle: "Learn More",
    //video: vimeoVideoUrls['interactiveInstallations'],
    link: "/services/A06",
  },
  {
    id: 4,
    img: '',
    title: "AI",
    desc: `<p>AI Transforms real estate with streamlining property searches, and enhancing customer experiences. Automated processes, personalized recommendations, and data-driven insights optimize decision-making, driving efficiency and innovation in the industry.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Learn More",
    video: vimeoVideoUrls['AI'],
    link: "/solutions/S03",
  },
  {
    id: 5,
    img: '',
    iframe:'https://portal.arviewz.com/products/346/modelviewer?arModel=true',
    title: "Smart Stands",
    desc: `<p>Smart stands redefine real estate presentations. Integrating interactive displays and technology, they offer immersive property experiences. Engage clients with digital floor plans, VR property tours, and dynamic visualizations for informed decision-making.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
  {
    id: 6,
    img: '',
    title: "Model AR",
    backTransparent: true,
    desc: `<p>Transform real estate with Model AR. Experience properties in augmented reality, allowing users to visualize architectural models, explore designs, and assess spatial concepts, creating an immersive and insightful property preview. Projecting floor plans on the floors are just from the past.`,
    button:false ,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['modelAr'],
    link: "",
  },
];

export default dataItem;
