import React , {useState} from 'react';
import PropTypes from 'prop-types';
import portfolioImg from '../../assets/images/layouts/portfolio1.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


Roadmap2.propTypes = {
    data: PropTypes.array,
    headerData: PropTypes.object,
};
Roadmap2.defaultProps = {
    data:[],
    headerData: {
        subheading:'',
        heading:'',
        img: portfolioImg,
        hoverImg: portfolioImg,
    }
};

function Roadmap2(props) {
    const {data, headerData} = props;
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <section className="roadmap s2">
                    <div className="shape"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center">
                                    {headerData.subheading && <h6 className="sub-heading"><span>{headerData.subheading}</span></h6>}
                                    {headerData.heading && <h3 className="heading pdb">{headerData.heading}</h3>}
                                </div>

                                <div className="roadmap__main s1">
                                <Swiper
               
                                    spaceBetween={30}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            },
                                        500: {
                                            slidesPerView: 2,
                                            },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1020: {
                                            slidesPerView: 4,
                                        },
                                        1250: {
                                            slidesPerView: 6,
                                        },
                                    }}
                                    className="roadmap-swiper"
                                    loop= {true}

                                    modules={[Autoplay, Pagination, Navigation]}
                                    
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                >
                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <div className="roadmap-box">
                                                        {data.time && <div className="time">{idx.time}</div>}
                                                        <div className="content">
                                                            <h5 className="title">{idx.title}</h5>
                                                            <p className="text">{idx.desc}</p>
                                                            {idx.img && <div className='p-4 center'>
                                                            <img src={idx.img} alt="AVRLabs" /> </div>}
                                                        </div>
                                                    </div>
                                        </SwiperSlide>
                                    ))
                                }
                                </Swiper>


                                    
                                </div>

                                {/* {headerData.img && <div className="road-map-img center">
                                    <img src={headerData.img} alt="AVRLabs"/>
                                </div>
                                } */}
                                {headerData.img && <div className="road-map-img center"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    <img src={headerData.hoverImg ? (isHovered ? headerData.hoverImg : headerData.img) : headerData.img} alt="AVRLabs" />
                                </div>}
                            </div>
                            
                        </div>
                    </div>
                </section>
    );
}

export default Roadmap2;