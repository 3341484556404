
const dataPortfolio = [

    {
        id: 'One',
        title: 'Engage Like Never Before',
        desc: 'Step into a 3D digital environment where your audience can explore your offerings in unprecedented detail.'
    },
    {
        id: 'Two',
        title: 'Dynamic Content',
        desc: 'Bring your content to life with interactive elements, including videos, images, and links, that captivate and inform visitors.'
    },
    {
        id: 'Three',
        title: 'Stay Relevant',
        desc: 'Keep your website up-to-date with real-time content updates, eliminating the need for constant external support.'
    },
    {
        id: 'Four',
        title: 'Cost-Effective Solutions',
        desc: 'Our subscription-based model ensures ongoing updates without the hefty price tag, allowing you to maintain a dynamic online presence without breaking the bank.'
    },
]

export default dataPortfolio;