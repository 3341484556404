import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Hospitality-Hero.jpg"
import HospitalityData from "../../assets/data/hospitality.js";

function Hospitality(props) {
    const desc ="Hospitality encompasses various services and industries related to hosting and accommodating guests, ensuring their comfort, satisfaction, and overall positive experiences. This sector includes hotels, restaurants, travel, tourism, and event management.";
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Hospitality" subheading="Industries" desc={desc}/>
            <ArtDetails data={HospitalityData}/>
            <Footer />
        </div>
    );
}

export default Hospitality;