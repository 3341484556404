

const dataPortfolio = [

    {
        id: 1,
        title: 'Transparent Display Technology',
        desc: 'Utilizing advanced transparent display technology, AVR Labs Transparent Screens allow viewers to see through the screen while still displaying dynamic content. This creates a unique and captivating viewing experience, ideal for a wide range of applications.'
    },
    {
        id: 2,
        title: 'High-Quality Visuals',
        desc: 'Delivering stunning visual quality, AVR Labs Transparent Screens boast high-resolution displays with vivid colors and crisp clarity. Whether showcasing products, advertisements, or informational content, these screens ensure maximum impact and engagement.'
    },
    {
        id: 3,
        title: 'Versatile Applications',
        desc: 'From retail stores and museums to corporate offices and trade shows, AVR Labs Transparent Screens are versatile and adaptable to various environments. They can be used for product displays, interactive exhibits, informational kiosks, and more, enhancing the overall experience for viewers.'
    },
    {
        id: 4,
        title: 'Customizable Design',
        desc: 'With customizable options available, including screen size, aspect ratio, and touchscreen capabilities, AVR Labs Transparent Screens can be tailored to suit specific requirements and branding preferences. This flexibility allows for seamless integration into any space or application.'
    },
    {
        id: 5,
        title: 'Plug-and-Play Functionality',
        desc: ` Designed for ease of use, AVR Labs Transparent Screens feature plug-and-play functionality, allowing for quick and hassle-free setup. Simply connect your desired content source, and you're ready to captivate your audience with immersive visuals.`
    },
    {
        id: 6,
        title: 'Remote Management',
        desc: 'With remote management capabilities, users can easily control and manage content displayed on AVR Labs Transparent Screens from anywhere with an internet connection. This enables real-time updates, scheduling, and monitoring, ensuring optimal performance at all times.'
    },


]

export default dataPortfolio;