
const dataPortfolio = [

    {
        id: 1,
        title: 'Expertise',
        desc: `Our team of experts has years of experience in the XR technology industry. We have a deep understanding of the latest trends and developments in the field, and we use that knowledge to provide our clients with the most innovative and effective solutions.`,
        show: 'true'
    },
    {
        id: 2,
        title: 'Customized Solutions',
        desc: `We understand that every business is unique, which is why we offer customized XR solutions tailored to meet the specific needs of our clients. We work closely with our clients to understand their goals and objectives, and we develop solutions that are designed to help them achieve those goals.`
    },
    {
        id: 3,
        title: 'Quality',
        desc: 'We take great pride in the quality of our work. We use the latest technology and equipment to ensure that our solutions are of the highest quality and deliver the best possible results for our clients.'
    },
    {
        id: 4,
        title: 'Customer Service',
        desc: 'At AVR Labs, we’re committed to providing exceptional customer service. We understand that our clients’ success is our success, which is why we work tirelessly to ensure that our clients are satisfied with the solutions we provide.'
    },
    {
        id: 5,
        title: 'Innovation',
        desc: 'We believe in the power of immersive technologies and their ability to transform and revolutionize the business world'
    },
]

export default dataPortfolio;