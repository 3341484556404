import React from 'react';
import Banner from '../../components/banner/SolutionBanner';
import Footer from '../../components/footer/Footer';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import dataHolodisplay from '../../assets/data/data-holo-display-speciality';
import Create from '../../components/create/Create2';
import AboutSolution from '../../components/about/AboutSolution';
import sectionOne from '../../assets/data/data-display-section-one';
import sectionThree from '../../assets/data/data-display-section-three';
import sectionTwo from '../../assets/data/data-display-section-two';
import sectionFour from '../../assets/data/data-display-section-four';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import heroImg from "../../assets/solutions/Holograpic displays/Hero.jpg";
import ctaImg from "../../assets/solutions/Holograpic displays/CTA.png";
function Holo(props) {
     const bannerData = {
        heading: 'Holographic Displays',
        subheading:"Experience the Future of Display Technology with AVR Labs",
        desc:"Introducing a new era of visual communication with our groundbreaking Holographic Displays. Captivate your audience with lifelike 3D visuals that seem to leap off the screen, creating an immersive experience like no other. Our displays are revolutionizing the way brands connect with their audience, offering unparalleled engagement and impact.",
        images:[heroImg]
    };
    const SpecialityData = {
        heading: 'Revolutionizing Brand Engagement',
    };
    const contactData = {
        heading: 'Transform Your Brand Today with Holographic <br> Displays',
        btn: true,
        btnTitle: 'Schedule a Call with Our Experts',
        link: '/contact',
        img:ctaImg
    }
    return (
        <div className='home-2 wrapper'>
                <Banner data={bannerData} />
                <AboutSolution data={sectionOne} />
                <SolutionSpeciality data={dataHolodisplay} headerData={SpecialityData}/>
                <ServiceToken2 data={sectionTwo} />
                <ServiceToken data={sectionThree} />
                <ServiceToken2 data={sectionFour} />
                <Create style={{ marginTop: "100px" }} data={contactData} />
                <Footer />
        </div>
    );
}

export default Holo;