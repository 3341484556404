import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Link } from 'react-router-dom';

RelatedProject.propTypes = {
    data: PropTypes.array,
    project: PropTypes.object
};

function RelatedProject(props) {
    const { data, project } = props;
    const [dataBlock] = useState({
        subheading: 'Our Speciality',
        heading: 'Related Projects',
    });
    const relatedProjects = data.filter(idx =>
        idx.id !== project.id &&
        idx.tags.some(tag => project.tags.includes(tag))
    );
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const showPagination = (
        (screenWidth < 768 && relatedProjects.length > 1) || 
        (screenWidth >= 768 && screenWidth < 1024 && relatedProjects.length > 2) || 
        (screenWidth >= 1024 && relatedProjects.length > 3)
    );
    const enableNavigation = relatedProjects.length > 3;
    const enableLoop = screenWidth < 768;
    if (relatedProjects.length === 0) {
        return null;
    }
    return (
        <section className="project">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading">{dataBlock.heading}</h3>
                        </div>

                        <Swiper
                            className="project-swiper"
                            spaceBetween={30}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                            }}
                            loop={enableLoop}
                            modules={[Navigation, Pagination]}
                            navigation={enableNavigation}
                            pagination={showPagination ? { clickable: true } : false}
                        >
                            {relatedProjects.map(idx => (
                                <SwiperSlide key={idx.id}>
                                    <div className="swiper-slide">
                                        <div className="project-box">
                                            <div className="image">
                                                <Link to={idx.link ? idx.link : `/project/${idx.slug}`}
                                                    target={idx.link ? "_blank" : ""}
                                                    rel={idx.link ? "noopener noreferrer" : ""}
                                                >
                                                    <img src={idx.img} alt={idx.title} />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <Link to={idx.link ? idx.link : `/project/${idx.slug}`}
                                                        target={idx.link ? "_blank" : ""}
                                                        rel={idx.link ? "noopener noreferrer" : ""}
                                                        className="h5 title">{idx.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RelatedProject;
