
import img1 from '../../assets/solutions/Holograpic displays/Aerial Display.jpg';

const dataBox = {
        heading: 'Aerial Display',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `Utilizing ultra-precision prismatic elements, it achieves extreme brightness and clarity, making it ideal for various applications. From retail displays to corporate presentations and museums, the Aerial Display offers customizable solutions to meet diverse project requirements, providing an immersive visual experience for audiences.`,
        img:img1
    };

export default dataBox;