import React, { useState } from "react";
import PropTypes from "prop-types";
ArtBanner.propTypes = {
  image: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  desc: PropTypes.string,
};
ArtBanner.defaultProps = {
  heading: "",
  subheading: "",
  desc: '',
};
function ArtBanner(props) {
  const { image, heading, subheading, desc } = props;
  const bannerStyle = {
    position: "relative",
    minHeight: "400px",
    backgroundImage : `url(${image})`,
    backgroundSize : 'contain',
    backgroundPosition : 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <section className="banner top-industry-banner s2 height-85vh" style={bannerStyle}>
      {(heading || subheading || desc) &&
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="block-text center aligned-center-div">
                  {heading && <h2 className="heading mt-5">{heading}</h2>}
                  {subheading && <h6 className="sub-heading">
                    <span>{subheading}</span>
                  </h6>}
                  {desc && <p className="mb-34 font-20 pd-50">{desc}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>}
    </section>
  );
}

export default ArtBanner;
