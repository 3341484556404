
const dataPortfolio = [

    {
        id: "One",
        title: 'Immersive Experiences',
        desc: `Our interactive kinetic games transport participants into a world of excitement and challenge, where every movement matters. With advanced sensor technology and responsive gaming floors, we create immersive experiences that keep audiences engaged and entertained.`
    },
    {
        id: "Two",
        title: 'Customizable Solutions',
        desc: `From large-scale events to intimate gatherings, our interactive kinetic games can be tailored to suit your unique needs and objectives. Whether you're looking to promote a product, drive engagement, or simply entertain your audience, we have the perfect solution for you.`
    },
    {
        id: "Three",
        title: 'Seamless Integration',
        desc: `Our interactive kinetic games seamlessly integrate with your event space, incorporating cutting-edge sensor technology and high-definition screens to deliver seamless gameplay experiences. Whether you're hosting a conference, trade show, or corporate event, our games will leave a lasting impression on your audience.`
    },
]

export default dataPortfolio;