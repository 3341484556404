import aiImg from "../industries/Health Care/AI.jpg";
import PrintOutsImg from "../industries/Health Care/interactive print-outs.jpg";
import virtualExperience from "../industries/Health Care/Virtual-Experience.jpg";

const vimeoVideoUrls = {
  immensiveTranining: "https://player.vimeo.com/video/936132252?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",    
};
const dataItem = [
  {
    id: 1,
    img: aiImg,
    iframe: '',
    title: "AI",
    desc: `<p>Innovate patient education with Interactive Print-Outs. Merge traditional informational materials with digital interactivity, empowering patients to access supplementary content and engage with health information in a dynamic manner.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Learn more",
    video: '',
    link: "/solutions/S03",
  },
  {
    id: 2,
    img: '',
    iframe: 'https://www.avrhub.net/Joelle-Tour/',
    title: "Interactive Virtual Tour",
    desc: `<p>Revolutionize healthcare facilities with Interactive Virtual Tours, offering immersive explorations for patients and staff, fostering familiarity and enhancing the overall experience within medical environments.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn more",
    video:'',
    link: "/services/A08",
  },
  {
    id: 3,
    img: '',
    title: "Immersive Training",
    desc: `<p>Integrate AI into healthcare for advanced diagnostics, personalized treatment plans, and operational efficiency. Leverage artificial intelligence to enhance patient care, streamline processes, and drive innovations in medical research and practice.</p><p>Elevate healthcare training through Immersive Training experiences. Utilize virtual simulations and interactive scenarios to enhance skills, ensuring medical professionals are well-prepared for real-world challenges.<p>`,
    button: true,
    slug: "A03",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['immensiveTranining'],
    link: "/services/A03",
  },
  {
    id: 4,
    img: virtualExperience,
    iframe: '',
    title: "Virtual Experience",
    desc: `<p>Transform patient care with Virtual Experiences. Offer virtual consultations, treatment explanations, and personalized health information, fostering patient engagement and providing accessible healthcare resources in a digital format.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
  {
    id: 5,
    img: PrintOutsImg,
    iframe: '',
    title: "Interactive Print-Outs",
    desc: `<p><p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: '',
    link: "/services/A02",
  },  
];

export default dataItem;
