import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import img from "../../assets/images/layouts/avt-01.png";

Projects.propTypes = {
  data: PropTypes.array,
};

function Projects(props) {
  const { data } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tagParam = queryParams.get("tag");
  const [selectedTag, setSelectedTag] = useState(tagParam || "");
  const [filteredData, setFilteredData] = useState(data);
  const allTags = data.flatMap((project) => project.tags);
  const uniqueTags = [...new Set(allTags)];

  useEffect(() => {
    if (selectedTag) {
      const filteredProjects = data.filter((idx) =>
        idx.tags.includes(selectedTag)
      );
      setFilteredData(filteredProjects);
    } else {
      setFilteredData(data);
    }
  }, [data, selectedTag]);
  const handleTagChange = (event) => {
    const tag = event.target.value;
    setSelectedTag(tag);
    
    const searchParams = new URLSearchParams(window.location.search);
    if (tag) {
      searchParams.set('tag', tag);
    } else {
      searchParams.delete('tag');
    }
    
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };
  

  return (
    <section className="nft">
      <div className="container">
        <div className="row">
          <div className="col-md-4" style={{marginBottom:'10px',marginLeft:'auto'}}>
            <div className="form-box p-2 w-100">
              <label className="ml-3">Tags</label>
              <select className="form-control w-100" id="tags" onChange={handleTagChange} value={selectedTag}>
                <option value=''>All Tag</option>
                {uniqueTags.map(tag => (
                    <option key={tag} value={tag}>{tag}</option> 
                ))}
                </select>
            </div>
          </div>
        </div>
        <div className="row">
          {filteredData.sort((a, b) => b.id - a.id).map((idx) => (
            <div key={idx.id} className="col-xl-6 col-md-12">
              <Link
                to={idx.link ? idx.link : `/project/${idx.slug}`}
                target={idx.link ? "_blank" : ""}
                rel={idx.link ? "noopener noreferrer" : ""}
                className="nft-item w-100" 
              >
                <div className="card-media">
                  <img src={idx.img} alt={idx.title} className="w-100" />
                </div>
                <div className="card-title">
                  <h5>{idx.title}</h5>
                </div>
                <div className="meta-info"></div>
              </Link>
            </div>
          ))}
          {filteredData.length === 0 && (
            <div className="col-xl-12 col-md-12 text-center">
                <div className="nft-item">
                      <div className="card-media">
                        <img src={img} alt="no project" />
                        </div>
                        <div className="card-title">
                          <h5>No project found</h5>
                        </div>
                        <div className="meta-info"></div>
                </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Projects;
