
import img1 from '../../assets/Services/metaverse-services/metaverse main.jpg'
import img2 from '../../assets/Services/metaverse-services/metaverse mini (1).jpg'
import img3 from '../../assets/Services/metaverse-services/metaverse mini (3).jpg'
import img5 from '../../assets/Services/metaverse-services/metaverse mini (2).jpg'

const dataBox = {
        heading: 'Elevate Metaverse Services with Immersive Experiences',
        button: true,
        link:'https://calendly.com/raed-avr/45min?month=2024-04',
        target:"_blank",
        btnTitle:'Book your Consultation Call',
        desc: `A groundbreaking opportunity to redefine customer engagement, collaboration, and innovation by leveraging immersive technologies. `,
        desc2: `Create immersive digital experiences that transcend traditional boundaries.`,
        dataImg:[
            {
                id: 1,
                img : img1,
                class: 'img1'
            },
            {
                id: 2,
                img : img2,
                class: 'img2'
            },
            {
                id: 3,
                img : img3,
                class: 'img3'
            },
            {
                id: 5,
                img : img5,
                class: 'img5'
            },
        ]
    };

export default dataBox;