import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/images/layouts/banner.png";
import ManufacturersData from "../../assets/data/manufacturers.js";

function Manufacturers(props) {
    return (
        <div className='home-2 wrapper'>
                <ArtBanner image={bannerImg} heading="Manufacturers" subheading="Industries" />
                <ArtDetails data={ManufacturersData}/>
                <Footer />
        </div>
    );
}

export default Manufacturers;