const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'Industries',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Art',
                links: '/industries/art'
            },
            {
                id: 2,
                sub: 'Retail Stores',
                links: '/industries/retail-stores'
            },
            {
                id: 3,
                sub: 'Real Estate',
                links: '/industries/real-estate'
            },
            {
                id: 4,
                sub: 'Education',
                links: '/industries/education'
            },
            // {
            //     id: 5,
            //     sub: 'Manufacturers',
            //     links: '/industries/manufacturers'
            // },
            {
                id: 6,
                sub: 'Ecommerce',
                links: '/industries/ecommerce'
            },
            {
                id: 7,
                sub: 'Events',
                links: '/industries/events'
            },
            {
                id: 8,
                sub: 'Architecture',
                links: '/industries/architecture'
            },
            {
                id: 9,
                sub: 'Health Care',
                links: '/industries/healthcare'
            }, 
            {
                id: 10,
                sub: 'Hospitality',
                links: '/industries/hospitality'
            },
            {
                id: 11,
                sub: 'Marketing',
                links: '/industries/marketing'
            },
        ]
    },
    {
        id: 3,
        name: 'Services',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Extended Reality (XR MR AR VR)',
                links: '/services/A02'
            },
            {
                id: 2,
                sub: 'Metaverse Services',
                links: '/services/A03'
            },
            {
                id: 3,
                sub: 'Virtual Web Development',
                links: '/services/A05'
            },
            {
                id: 4,
                sub: 'Interactive Virtual Tours',
                links: '/services/A08'
            },
            {
                id: 5,
                sub: 'Creative Concepts, Activation & Events',
                links: '/services/A06'
            },
            {
                id: 6,
                sub: 'Interactive Games',
                links: '/services/A04'
            },
        ]
    },
    {
        id: 4,
        name: 'Solutions',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'AR Visualizer',
                links: '/solutions/S01'
            },
            {
                id: 2,
                sub: 'Virtual Designs Hub',
                links: '/solutions/S02'
            },
            {
                id: 3,
                sub: 'AI Virtual Beings',
                links: '/solutions/S03'
            },
            {
                id: 4,
                sub: 'Transparent Screens',
                links: '/solutions/S05'
            },
            {
                id: 5,
                sub: 'Holographic Displays',
                links: '/solutions/S07'
            }, 
            {
                id: 6,
                sub: 'Virtual Try-On',
                links: '/solutions/S08'
            },
            {
                id: 7,
                sub: 'Smart Stands',
                links: '/solutions/S04'
            },
        ]
    },
    {
        id: 5,
        name: 'Company',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'About Us',
                links: '/about'
            },
            {
                id: 2,
                sub: 'Projects',
                links: '/projects'
            },
        ]
    },
    {
        id: 6,
        name: 'Contact',
        links: '/contact',
    },
    // {
    //     id: 89,
    //     name: 'Blog',
    //     links: '/blog'
    // },
    
]

export default menus;