import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/E-Commerce-Hero.jpg"
import ecommerceData from "../../assets/data/ecommerce.js";

function Ecommerce(props) {
    const desc ="E-commerce, or electronic commerce, refers to the buying and selling of goods and services over the internet. It encompasses online retail, digital transactions, and electronic business operations, reshaping the global marketplace and customer shopping experiences.";
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Ecommerce" subheading="Industries" desc={desc}/>
            <ArtDetails data={ecommerceData}/>
            <Footer />
        </div>
    );
}

export default Ecommerce;