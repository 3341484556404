import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function SuccessPage() {
    return (
        <div className='wrapper pt-6'>
            <Helmet>
                <script type='text/javascript'>
                {`
                    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                    r.src=n;var u=t.getElementsByTagName(s)[0];
                    u.parentNode.insertBefore(r,u);})(window,document,
                    'https://sc-static.net/scevent.min.js');
                    
                    snaptr('track', 'PAGE_VIEW');
                    snaptr('track', 'CUSTOM_EVENT_1', {'uuid_c1': 'INSERT_UUID_C1', 'sign_up_method': 'INSERT_SIGN_UP_METHOD', 'user_email': 'INSERT_USER_EMAIL', 'user_phone_number': 'INSERT_USER_PHONE_NUMBER'})
                `}
                </script>
            </Helmet>
            <PageTitle title='Thank You!' />
            <section className="touch">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='mb-2'>Thank You for Reaching Out to AVR Labs!</h1>
                            <p className='mb-2'>We've received your message and appreciate your interest in AVR Labs' XR solutions. Our team will get back to you shortly.</p>
                            <p className='mb-2'>In the meantime, feel free to explore more about our XR innovations and how they can transform your business.</p>
                            <p className='mb-3'>Have a look at our successful project: <Link to="/projects">Projects</Link>.</p>
                            <h6>Thank you once again for contacting AVR Labs. We look forward to connecting with you soon!</h6>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
export default SuccessPage;
