
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const features = [

    {
        id: 1,
        img:img1,
        title: 'Responsive',
        desc: 'Seamlessly view tours across multiple devices.'
    },
    {
        id: 2,
        img:img2,
        title: 'Editor UI',
        desc: 'Customize tours with ease using our intuitive interface.'
    },
    {
        id: 3,
        img:img3,
        title: 'Panoramas',
        desc: 'Explore different panorama types with 360 support.'
    },
    {
        id: 4,
        img:img4,
        title: 'Multi Language',
        desc: 'Choose from over 20 languages to enhance accessibility.'
    },
    {
        id: 5,
        img:img2,
        title: 'FAST PRELOAD',
        desc: 'Enjoy fast loading times for a smooth navigation experience.'
    },
    {
        id: 6,
        img:img1,
        title: 'Markers',
        desc: 'Navigate easily between points of interest.'
    },
    {
        id: 7,
        img:img2,
        title: 'POIs',
        desc: 'Showcase multimedia content within your tours.'
    },
    {
        id: 8,
        img:img3,
        title: 'Import / Export',
        desc: 'Download tours as standalone packages or importable files.'
    },
    {
        id: 9,
        img:img4,
        title: '3D View (Dollhouse)',
        desc: 'Create a 3d View (know as dolhouse) and show your tour in an immersive and interactive mode.'
    },
]

export default features;