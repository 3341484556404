
import img1 from '../solutions/AR Viewz/icons/visualization.png'
import img2 from '../solutions/AR Viewz/icons/decor.png'
import img3 from '../solutions/AR Viewz/icons/wall.png'
import img4 from '../solutions/AR Viewz/icons/art.png'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Product visualizer',
    },
    {
        id: 2,
        img: img2,
        title: 'Home decor items',
    },
    {
        id: 3,
        img: img3,
        title: 'Wall and floor covering',
        desc: ''
    },
    {
        id: 4,
        img: img4,
        title: 'Art',
    },


]

export default dataPortfolio;