
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        title: 'Designs Representation',
        desc: 'Step into your designs and engage with them like never before. From videos to forms, slides to animations, our platform breathes life into your designs, offering an immersive experience for your clients.'
    },
    {
        id: 2,
        title: 'Projects Workflow',
        desc: 'Streamline your project management with preset folders and templates that help you organize documents and files efficiently. Spend less time on paperwork and more time on what matters most—running your business.'
    },
    {
        id: 3,
        title: 'XR (Extended Reality) Technologies',
        desc: 'Embark on your journey to the Metaverse with our VR and AR features. Our web-based platform allows viewers to experience immersive content without the need for additional apps, making it accessible to everyone.'
    },
    {
        id: 4,
        title: 'ISO 9001 Certificate',
        desc: 'Simplify the process of obtaining ISO 9001 certification with Virtual Designs Hub. Partner with us to fast-track your journey to this prestigious quality standard, recognized worldwide for its excellence.'
    },

]

export default dataPortfolio;