import React from 'react';

import Footer from '../components/footer/Footer';
import PageTitle from '../components/pagetitle/PageTitle';

import Projects from '../components/project/Projects';
import dataProject from '../assets/data/data-project';



function Project(props) {
    return (
        <div className='wrapper pt-6'>

            <PageTitle title='Projects' menu="Company" />

            <Projects data={dataProject} />
            
            <Footer />
            
        </div>
    );
}

export default Project;