import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Partner from '../partner/Partner';

Team.propTypes = {
    data: PropTypes.array,
    headerData: PropTypes.object
};

function Team(props) {

    const { data, headerData } = props;


    return (
        <section className="team">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{headerData.subheading}</span></h6>
                            <h3 className="heading pd">{headerData.heading}</h3>
                        </div>
                        <div className="row">
                            {
                                data.map(idx => (
                                    <div className="col-xl-4 col-md-6 col-12" key={idx.id}>
                                        <div className="team-box ">
                                            {idx.img &&
                                                <div className="image">
                                                    <img src={idx.img} alt="AVRLabs" />
                                                </div>}
                                            {idx.iframe && (
                                                <div className="project-box my-0">
                                                    <div style={{ height: "%", position: "relative" }} className="iframe">
                                                        <iframe
                                                            src={idx.iframe}
                                                            frameBorder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                                            title={idx.name}
                                                            className="embed-responsive-item"
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="speciality-box" >
                                                <h5 className="title">{idx.name}</h5>
                                                <h6 className="postion">
                                                    {idx.position}
                                                </h6>
                                                <p>{idx.desc}</p>
                                                <p>{idx.desc2}</p>
                                                <p>{idx.desc3}</p>
                                                <h3 className="number">0{idx.id}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>

                </div>
            </div>
        </section >
    );
}

export default Team;