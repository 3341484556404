
const dataPortfolio = [

    {
        id: 1,
        title: 'True-to-Life Simulation',
        desc: 'Immerse yourself in lifelike virtual simulations that accurately represent clothing, accessories, and beauty products, ensuring a seamless shopping experience.'
    },
    {
        id: 2,
        title: 'Augmented Reality Integration',
        desc: 'Explore products in your own environment with Augmented Reality (AR) technology, allowing you to visualize items in real-world settings.'
    },
    {
        id: 3,
        title: 'Customization Options',
        desc: 'Tailor your virtual try-on experience with customizable features, including color selection, size adjustment, and style variations, to match your unique preferences.'
    },
    {
        id: 4,
        title: 'Seamless Integration',
        desc: 'Effortlessly integrate our Virtual Try-On solution into your website or mobile app, providing a seamless shopping journey for your customers across various platforms.'
    },
    {
        id: 5,
        title: 'User-Friendly Interface',
        desc: 'Navigate and interact with ease through our intuitive user interface, designed for a smooth and enjoyable shopping experience.'
    },
]

export default dataPortfolio;