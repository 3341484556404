
import img1 from '../../assets/Services/creative-concepts-and-activation/Unleashing-Creative-Concepts.jpg'
const dataBox = {
        heading: 'Unleashing Creative Concepts & Activation Solutions for Unforgettable Experiences',
        subheading: 'Shine Bright',
        button: true,
        link:'/contact',
        btnTitle:'Schedule Your Consultation Call Today',
        desc: `Step into the spotlight and captivate your audience like never before. Captivate your audience with our cutting-edge creative concepts and activation solutions tailored for events, exhibitions, and tradeshows. Make your brand shine and leave a memorable impact on your audience.`,
        //img:img1
        dataImg: [
            {
                id: 1,
                img : img1,
                class: 'img1'
            }
        ]
    };

export default dataBox;