import React from 'react';
import dataArviewzPortfolio from '../../assets/data/data-arviewz-portfolio';
import ArviewsdataFaqs from '../../assets/data/data-arviewz-faq';
import Banner from '../../components/banner/SolutionBanner';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import SolutionCounter from '../../components/counter/SolutionCounter';
import SolutionFaqs from '../../components/faqs/SolutionFaqs';
import Footer from '../../components/footer/Footer';
import portfolioImg from '../../assets/solutions/AR Viewz/portfolio.png';
import viewzCounterImg from '../../assets/solutions/AR Viewz/counter.png';
import headerVideo from '../../assets/solutions/AR Viewz/hero-arviews.mp4';
import Contact from '../../components/contact/Contact';
import AboutService2 from '../../components/about/AboutService2';
import arviewsVideo from '../../assets/solutions/AR Viewz/AR Viewz 3.mp4';

function Arviewz(props) {
    const counterHeaing = "Pushing the Boundaries of Technology";
    const counterDesc = "We're committed to delivering reliable, customer-centric solutions that push the boundaries of technology. With AR Viewz, businesses can stay ahead of the curve and delight customers with cutting-edge AR experiences that set them apart from the competition.";
    const bannerData = {
        subheading: 'Let’s Transform Your Business',
        heading: 'Welcome to AR Viewz by AVR Labs',
        video: headerVideo,
        objFitContain: true
    };
    const headerData = {
        img: portfolioImg,
        subheading: 'See How AR Can Transform Your Business',
        heading: 'AR Applications Across Industries',
        desc: 'From home decor to art galleries, tile flooring to carpet retailers, AR Viewz offers transformative benefits that revolutionize the way businesses engage with customers.',
    };
    const faqHeader = {
        heading: 'See Your Products in a Whole New Light!',
        desc1: 'With AR Viewz, customers can visualize products in their own space using their smartphone or tablet camera. From furniture to home decor, artwork to apparel, our AR experiences bring products to life with stunning realism and accuracy. Say goodbye to guesswork and hello to confident purchasing decisions.',
    }
    const viewzCounter = [
        {
            count: '60% ',
            title: "Prefer AR Shopping"
        },
        {
            count: "40% ",
            title: 'More Conversions'
        },
        {
            count: '60%',
            title: "Reduced Returns"
        }
    ];
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <SolutionPortfolio headerData={headerData} data={dataArviewzPortfolio} step="false" />
            {arviewsVideo && ( <section className="about">
                <div className="shape right"></div>
                    <div className="container">
                    <div className="row text-center">
                        <div className="col-xl-12 col-md-12">
                            <div className="project-box">
                                <div style={{ padding: '56.25% 0 0 0',position: 'relative' }}>
                                <iframe
                                    src={arviewsVideo}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                    title="AVRLabs"
                                    className="embed-responsive-item"
                                ></iframe>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </section>)}
            <section className="py-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h3 className="heading">{counterHeaing}</h3>
                                <p>{counterDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="couter p-y my-5">
                <SolutionCounter data={viewzCounter} icon="false" showimg="true" image={viewzCounterImg} />
            </section>
            <SolutionFaqs data={ArviewsdataFaqs} dataBlock={faqHeader} />
            <Contact subheading='Let’s Talk' heading="Transform Your Business with AR Viewz" />
            <Footer />
        </div>
    );
}

export default Arviewz;